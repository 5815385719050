import { useEffect, useState } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import Spin from "../../../components/Spin";
import { Card, Row, Col, Select, Image, Button, Modal } from "antd";
import { Link, useHistory, useParams } from "react-router-dom";
// import moment from 'moment';
import { currencyFormat } from "../../../utils/format";
import CriptoStatus1 from "../../../assets/images/cripto-status-1.svg";
import CriptoStatus2 from "../../../assets/images/cripto-status-2.svg";
import CriptoStatus3 from "../../../assets/images/cripto-status-3.svg";
import IconEmail from "../../../assets/images/icono-email.svg";
import LogoBlack from "../../../assets/images/logo-black.svg";
import { lPhantFAQSLinkDisplay } from "../../../utils/variables";

import "./Status.scss";
import { injectIntl } from "react-intl";

const CREDITS = gql`
  query CreditsByToken(
    $isQueued: Boolean
    $isPending: Boolean
    $isOpen: Boolean
    $status: CreditStatus
    $page: Int
    $pageSize: Int
  ) {
    creditsByToken(
      isQueued: $isQueued
      isPending: $isPending
      isOpen: $isOpen
      status: $status
      page: $page
      pageSize: $pageSize
    ) {
      results {
        id
        amount
        months
        monthlyPayment
        balance
        collateralRequired
        collateralUsed
        openingFee
        openingFeeIva
        paymentDates
        status
        paymentReports {
          id
          amount
          status
          principalDelta
          rechargeDelta
          rechargeIvaDelta
          interestDelta
          ivaDelta
          createdAt
        }
        pendingPaymentReports {
          id
        }
        deposit {
          reference
          comment
          depositedAt
          proof
        }
        payments {
          id
          scheduledDate
          scheduledAmount
          scheduledPrincipal
          scheduledInterest
          scheduledIva
          scheduledRecharge
          scheduledRechargeIva
          scheduledBalance
          isPaid
          paymentDate
          paymentAmount
          principal
          interest
          iva
          recharge
          rechargeIva
          isManual
        }
        nextPaymentDate
        nextPaymentData {
          amountToSettle
          pendingAmount
          recharge
          rechargeIva
        }
        nextPaymentIndex
      }
    }
  }
`;

const REQUEST_EMAIL_VERIFICATION_TOKEN = gql`
  mutation RequestEmailVerificationToken($email: String!) {
    requestEmailVerificationToken(email: $email)
  }
`;

const clientColor = "#FFD7AB";
const lphantColor = "#CCE3FF";

function Statistics(props) {
  const { get, intl, user } = props;
  const sendEmailModal = Modal;

  const [statusBanner, setStatusBanner] = useState();
  const [showBanner, setShowBanner] = useState(true);

  const { loading, error, data } = useQuery(CREDITS, {
    fetchPolicy: "no-cache",
    pollInterval: 60000,
    notifyOnNetworkStatusChange: true,
  });

  const [
    requestEmailVerificationTokenMutationCall,
    {
      loading: requestEmailVerificationTokenMutationLoading,
      error: requestEmailVerificationTokenMutationError,
    },
  ] = useMutation(REQUEST_EMAIL_VERIFICATION_TOKEN, {
    variables: { email: user?.email || "" },
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    onCompleted: () => {
      if (requestEmailVerificationTokenMutationError) {
        sendEmailModal.error({
          title: "",
          content: (
            <div className="email-modal">
              <Image src={LogoBlack} />
              <div>L-Phant</div>,
              <Image src={IconEmail} />
              <div>No hemos podido enviar el correo a:</div>
              <div>{user.email}</div>
              <div>Reintente mas tarde nuevamente.</div>
            </div>
          ),
          onOk() {
            setShowBanner(false);
          },
        });
      } else {
        sendEmailModal.info({
          title: "",
          content: (
            <div className="email-modal">
              <Image src={LogoBlack} />
              <div>L-Phant</div>,
              <Image src={IconEmail} />
              <div>Hemos reenviado un correo a:</div>
              <div>{user.email}</div>
              <div>
                Deberás verificar tu correo electrónico para poder continuar la
                solicitud de tu cripto crédito.
              </div>
            </div>
          ),
          onOk() {
            setShowBanner(false);
          },
        });
      }
    },
  });

  const { Option } = Select;

  const history = useHistory();
  const { id } = useParams();

  const resendVerificationEmail = () =>
    requestEmailVerificationTokenMutationCall();
  const requestCredit = () => history.push("/solicitud-de-credito");
  const requestPersonalInfo = () => history.push("/perfil");
  const goCredit = () => history.push(`/credito/:${creditSelected.id}`);
  const depositPayment = () => history.push(`/credito/:${creditSelected.id}`);

  const callsToAction = {
    EMAILVERIFICATIONPENDING: resendVerificationEmail,
    CREDITREQUESTPENDING: requestCredit,
    PERSONALINFOPENDING: requestPersonalInfo,
    KYCPENDING: null,
    COLLATERALPENDING: goCredit,
    PAYMENTPENDING: depositPayment,
    CREDITAPPROVED: null,
  };

  let creditSelected =
    (data?.creditsByToken.results || []).find((item) => item.id === id) || {};

  useEffect(() => {
    const creditId = data?.creditsByToken.results[0]?.id;
    if (!id && creditId) {
      history.push(`/dashboard/${creditId}`);
      window.location.reload();
    }
    if (!Object.keys(creditSelected).length && id && !loading) {
      history.push(`/dashboard`);
      window.location.reload();
    }
    if (data) {
      get(creditSelected);
    }
  }, [id, data]); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) return <Spin />;

  if (error) return `Error! ${error.message}`;

  const statusColor = (status) => {
    switch (status) {
      case "STARTED":
        return "#00bd00";
      case "DECLINED":
        return "red";
      default:
        return "#4B7EFF";
    }
  };

  const alertColor = (mot) => {
    switch (mot) {
      case "client":
        return clientColor;
      case "lphant":
        return lphantColor;
      default:
        return;
    }
  };

  if (user?.bannerStatus && !statusBanner) {
    let tempStatus = {
      status: user.bannerStatus,
      title: intl.formatMessage({
        id: `userStatus.${user.bannerStatus}`,
      }),
      content: intl.formatMessage({
        id: `userStatusContent.${user.bannerStatus}`,
      }),
      color: alertColor(
        intl.formatMessage({
          id: `userStatusColor.${user.bannerStatus}`,
        })
      ),
      buttonText: intl.formatMessage({
        id: `userStatusButtonText.${user.bannerStatus}`,
      }),
    };
    setStatusBanner(tempStatus);
  }

  return (
    <div className="Status">
      {data?.creditsByToken.results.length > 1 && (
        <Select
          style={{ width: "100%" }}
          placeholder="Ver otros créditos"
          dropdownClassName="statistics-dropdown"
        >
          {data?.creditsByToken.results.map((credit, index) => (
            <Option key={index} value={credit?.id}>
              <Link
                to={`/credito/${credit.id}`}
                style={{ color: statusColor(credit.status) }}
              >
                {currencyFormat(credit.balance)} (Pago mensual:{" "}
                {currencyFormat(credit.monthlyPayment)})
              </Link>
            </Option>
          ))}
        </Select>
      )}
      <div className="Status">
        {statusBanner && statusBanner.status !== null && showBanner && (
          <Row span={24} gutter={24}>
            <Col span={24}>
              <Card
                loading={!statusBanner.status && !creditSelected?.status}
                bordered
                bodyStyle={{
                  backgroundColor: `${statusBanner?.color || "#FFFFFF"}`,
                }}
                style={{ marginBottom: "3em" }}
              >
                <div className="status-content-1">
                  <div className="status-content-1-1">
                    <h3>{statusBanner?.title || ""}</h3>
                    {statusBanner?.content || ""}
                  </div>
                  <div className="status-content-1-2">
                    {callsToAction[statusBanner.status] && (
                      <Button
                        loading={requestEmailVerificationTokenMutationLoading}
                        onClick={(_) => callsToAction[statusBanner.status]()}
                      >
                        {statusBanner.buttonText}
                      </Button>
                    )}
                  </div>
                  <Button
                    ghost
                    style={{
                      color: `${statusBanner?.color || "#FFFFFF"}`,
                      filter: "brightness(80%)",
                    }}
                    onClick={(_) => setShowBanner(false)}
                  >
                    x
                  </Button>
                </div>
              </Card>
            </Col>
          </Row>
        )}
      </div>
      <Row justify="center" type="flex" span={24} gutter={[24, 36]}>
        <Col type="flex" xs={24} sm={16} md={8}>
          <Card className="cards">
            <div>
              {creditSelected.status === "CREDITREQUESTPENDING" ? (
                <div className="status-content-2">
                  <Image src={CriptoStatus1} />
                  <h3>
                    {intl.formatMessage({
                      id: "personal-credit-card-title",
                    })}
                  </h3>
                  <h3>
                    {intl.formatMessage({
                      id: "personal-credit-card-content",
                    })}
                  </h3>
                  <Link to={`/solicitud-de-credito?new`}>
                    <Button type="secondary">
                      {intl.formatMessage({
                        id: "personal-credit-card-button",
                      })}
                    </Button>
                  </Link>
                </div>
              ) : (
                <div className="status-content-2">
                  <Image src={CriptoStatus1} />
                  <h3>
                    {intl.formatMessage({
                      id: "credit-details-card-title",
                    })}
                  </h3>
                  <h3>
                    {intl.formatMessage({
                      id: "credit-details-card-content",
                    })}
                  </h3>
                  <Link to={`/credito/:${creditSelected.id}`}>
                    <Button type="secondary">
                      {intl.formatMessage({
                        id: "credit-details-card-button",
                      })}
                    </Button>
                  </Link>
                </div>
              )}
            </div>
          </Card>
        </Col>
        <Col xs={24} sm={16} md={8}>
          <Card className="cards">
            <div className="status-content-2">
              <Image src={CriptoStatus2} />
              <h3>
                {intl.formatMessage({
                  id: "wallet-card-title",
                })}
              </h3>
              <h3>
                {intl.formatMessage({
                  id: "wallet-card-content",
                })}
              </h3>
              <Link to="/billetera">
                <Button type="secondary">
                  {intl.formatMessage({ id: "wallet-card-button" })}
                </Button>
              </Link>
            </div>
          </Card>
        </Col>
        <Col xs={24} sm={16} md={8}>
          <Card className="cards">
            <div className="status-content-2">
              <Image src={CriptoStatus3} />
              <h3>
                {intl.formatMessage({
                  id: "products-card-title",
                })}
              </h3>
              <h3>
                {intl.formatMessage({
                  id: "products-card-content",
                })}
              </h3>
              <a
                target="_blank"
                rel="noopener noreferrer external"
                href={lPhantFAQSLinkDisplay}
                className="password-recovery-link"
              >
                <Button type="secondary">
                  {intl.formatMessage({ id: "products-card-button" })}
                </Button>
              </a>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default injectIntl(Statistics);
