import { useState } from "react";
import { Row, Col } from "antd";
import CreditInfo from "./CreditInfo";
import Statistics from "./Statistics";
import RequestNewCredit from "./RequestNewCredit";
import Layout from "../../components/Layout";

import "./Credit.scss";

function Credit() {
  const [creditSelected, setCreditSelected] = useState({});
  return (
    <div className="Credit">
      <Layout>
        <Statistics get={setCreditSelected} />
        <Row gutter={24}>
          <Col span={24}>
            <CreditInfo get={setCreditSelected} />
            {!creditSelected?.id && <RequestNewCredit />}
          </Col>
        </Row>
      </Layout>
    </div>
  );
}

export default Credit;
