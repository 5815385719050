import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
// import reportWebVitals from './reportWebVitals';

import "./index.scss";

ReactDOM.render(
  // <React.StrictMode>,
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  // </React.StrictMode>,
  document.getElementById("root")
);

// reportWebVitals(console.log);
