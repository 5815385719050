import React, { useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { gql, useMutation } from "@apollo/client";
import { notification } from "antd";
import Spin from "../../components/Spin";

import "./EmailVerify.scss";

const EMAIL_VERIFY = gql`
  mutation EmailVerify($token: String!) {
    emailVerify(token: $token) {
      id
    }
  }
`;

function EmailVerify() {
  const { token } = useParams();

  const history = useHistory();

  const [emailVerifyMutation, { loading }] = useMutation(EMAIL_VERIFY);

  useEffect(() => {
    if (token) {
      emailVerifyMutation({
        variables: {
          token,
        },
      }).then(() => {
        notification.success({
          message: "Tu correo se verificó exitosamente",
          duration: 2,
          onClose: () => {
            history.push("/dashboard");
          },
        });
      });
    }
  }, [token, emailVerifyMutation, history]);

  if (loading) return <Spin />;

  return <div className="EmailVerify" />;
}

export default EmailVerify;
