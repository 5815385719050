import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  createHttpLink,
} from "@apollo/client";
import { onError } from "apollo-link-error";
import { notification } from "antd";

const uri = process.env.REACT_APP_API_URL;

const httpLink = createHttpLink({
  uri,
  credentials: "include",
});

// (error.message.indexOf(`notEnoughBalanceInThisCrypto`) > -1)

// if (error.message.indexOf(`unathenticated`) > -1 || error.message.indexOf(`unauthorized`) > -1) {
//     window.location.pathname = 'iniciar-sesion';
// }
const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    let message = "";
    graphQLErrors.forEach((error) => {
      console.log("ERROR", error.message);
      switch (error.message) {
        case "unathenticated":
          message = "Sin autenticar";
          notification.open(message);
          window.location.pathname = "iniciar-sesion";
          break;

        case "unauthorized":
          message = "No estas autorizado a esta acción";
          notification.open(message);
          window.location.pathname = "iniciar-sesion";
          break;

        case "notEnoughBalanceInThisCrypto":
          message =
            "No tienes fondos suficientes en esta criptomoneda para retirar";
          break;

        case "notEnoughMxnBalance":
          message =
            "Tu colateral disponible es menor que la cantidad que intentas retirar";
          break;

        case "userHasPendingTransactions":
          message = "Aun existen transacciones pendientes por evaluar";
          break;

        case "alreadyRegistered":
          message = "Ya el usuario se encuentra registrado";
          break;

        default:
          message = error;
          break;
      }
      console.log("ERROR MESSAGE", message);
      notification.open(message);
    });
  }
});

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: errorLink.concat(httpLink),
});

export default function Provider(props) {
  return <ApolloProvider client={client}>{props.children}</ApolloProvider>;
}
