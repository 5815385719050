import React from "react";
import { Card, Button } from "antd";
import { useHistory } from "react-router-dom";
import { gql, useMutation } from "@apollo/client";
import { currencyFormat } from "../../../utils/format";
import Calculator from "../../../components/Calculator";

import "./Resume.scss";

const CREDIT_ADD = gql`
  mutation CreditAdd($creditToAdd: CreditToAdd!) {
    creditAdd(creditToAdd: $creditToAdd) {
      id
    }
  }
`;

function Resume(props) {
  const { value, product } = props;

  const history = useHistory();

  const [creditAddMutation, { loading }] = useMutation(CREDIT_ADD, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    onCompleted: () => {
      history.push("/credito-solicitado");
    },
  });

  const onSubmit = () => {
    creditAddMutation({
      variables: {
        creditToAdd: {
          amount: value.amount,
          months: value.months,
          product: product.id,
        },
      },
    });
  };

  return (
    <div className="Resume">
      <Card>
        <div className="col">
          <h1>{product.name}</h1>
          <p>{product.description}</p>
        </div>
        <div className="row">
          <div className="col">
            <div className="row spacer">
              <div className="col spacer">
                <label className="bold">Monto</label>
                <label>
                  {currencyFormat(Number(value.amount))} Pesos Mexicanos
                </label>
              </div>
            </div>
            <div className="row spacer">
              <div className="col spacer">
                <label className="bold">Periodo</label>
                <label>{value.months} meses</label>
              </div>
            </div>
          </div>
          <div className="col">
            <Calculator
              product={product}
              loanAmount={value.amount}
              loanMonths={value.months}
            />
          </div>
        </div>
        <div className="row row-center">
          <Button loading={loading} type="primary" onClick={onSubmit}>
            Confirmar
          </Button>
        </div>
      </Card>
    </div>
  );
}

export default Resume;
