import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";

import Login from "./containers/Login";
import SignUp from "./containers/SignUp";
import CreditRequest from "./containers/CreditRequest";
import CreditRequested from "./containers/CreditRequested";
import Dashboard from "./containers/Dashboard";
import Profile from "./containers/Profile";
import Wallet from "./containers/Wallet";
import Credit from "./containers/Credit";
import EmailVerify from "./containers/EmailVerify";
import UpdatePassword from "./containers/UpdatePassword";
import PasswordRecovery from "./containers/PasswordRecovery";
import ContactForm from "./containers/ContactForm/ContactForm";

export default function Router() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/contact-form">
          <ContactForm />
        </Route>
        <Route path="/dashboard/:id">
          <Dashboard />
        </Route>
        <Route path="/dashboard">
          <Dashboard />
        </Route>
        <Route path="/iniciar-sesion">
          <Login />
        </Route>
        <Route path="/registrarse">
          <SignUp />
        </Route>
        <Route path="/perfil">
          <Profile />
        </Route>
        <Route path="/billetera">
          <Wallet />
        </Route>
        <Route path="/credito/:id">
          <Credit />
        </Route>
        <Route path="/credito">
          <Credit />
        </Route>
        <Route path="/solicitud-de-credito">
          <CreditRequest />
        </Route>
        <Route path="/credito-solicitado">
          <CreditRequested />
        </Route>
        <Route exact path="/verificar-correo/:token">
          <EmailVerify />
        </Route>
        <Route exact path="/recuperar-contrasena">
          <PasswordRecovery />
        </Route>
        <Route path="/recuperar-contrasena/:token">
          <UpdatePassword />
        </Route>
        <Route path="/">
          <Redirect to="/iniciar-sesion" />
        </Route>
      </Switch>
    </BrowserRouter>
  );
}
