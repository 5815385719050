import React, { useState } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Link, useHistory } from "react-router-dom";
import { Form, Button, Input, notification } from "antd";
import { ReactComponent as Logo } from "../../assets/images/logo-black.svg";
import { gql, useMutation } from "@apollo/client";
import "./PasswordRecovery.scss";

const PASSWORD_RECOVERY = gql`
  mutation RequestPasswordToken($email: String!) {
    requestPasswordToken(email: $email)
  }
`;

function PasswordRecovery(props) {
  const history = useHistory();

  const [passwordRecoveryMutation, { loading }] =
    useMutation(PASSWORD_RECOVERY);

  const [form, setForm] = useState({});

  const onChange = (value) => {
    setForm({
      ...form,
      ...value,
    });
  };

  const onFinish = () => {
    passwordRecoveryMutation({
      variables: {
        email: form.email,
      },
    }).then((_) => {
      notification.success({
        message: "Hemos enviado un correo para restablecer tu contraseña",
        duration: 2,
        onClose: () => {
          history.push("iniciar-sesion");
        },
      });
    });
  };

  const { intl } = props;

  return (
    <div className="AuthForm row">
      <div className="col col-vertical-center col-center">
        <Form
          layout="vertical"
          name="passwordRecovery"
          onValuesChange={onChange}
          onFinish={onFinish}
        >
        <div className="logo">
        <Logo />
      </div>
          <h1>
            <FormattedMessage id="password-recovery-title"></FormattedMessage>
          </h1>
          <Form.Item
            label={intl.formatMessage({ id: "email-label" })}
            name="email"
            rules={[
              {
                type: "email",
                required: true,
                message: intl.formatMessage({ id: "email-required-error" }),
              },
            ]}
          >
            <Input
              type="email"
              placeholder={intl.formatMessage({
                id: "login-email-placeholder",
              })}
              size="large"
            />
          </Form.Item>
          <Form.Item>
            <Button
              loading={loading}
              disabled={!form.email}
              type="primary"
              htmlType="submit"
            >
              {intl.formatMessage({ id: "continue-button" })}
            </Button>
          </Form.Item>
          <p>
            <Link to="/iniciar-sesion">
              <FormattedMessage id="login-back"></FormattedMessage>
            </Link>
          </p>
        </Form>
      </div>
      <div className="col background-image"></div>
    </div>
  );
}

export default injectIntl(PasswordRecovery);
