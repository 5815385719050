import Layout from "../../components/Layout";
import { Card, Statistic, Button, Tooltip, notification } from "antd";
import { gql, useQuery } from "@apollo/client";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import getCryptoIcon from "../../constants/crypto-icons";
import Spin from "../../components/Spin";
import Transactions from "../Transactions";
import { currencyFormat } from "../../utils/format";
import { injectIntl } from "react-intl";
import { useState } from "react";
import DepositModal from "./DepositModal";
import CashoutModal from "./CashoutModal/CashoutModal";

import "./Wallet.scss";

const COLLATERAL = gql`
  query CollateralToken {
    collateralByToken {
      id
      balances {
        BTC
        ETH
      }
      mxnBalance
      usedAmount
      unusedAmount
      hasPendingTransactions
    }
  }
`;

function Wallet({ intl }) {
  const [depositModalVisible, setDepositModalVisible] = useState(false);

  const [cashoutModalVisible, setCashoutModalVisible] = useState(false);

  const { loading, data } = useQuery(COLLATERAL, {
    pollInterval: 60000,
  });

  if (loading) return <Spin />;

  let {
    balances,
    mxnBalance,
    unusedAmount,
    usedAmount,
    hasPendingTransactions,
  } = data?.collateralByToken || {};

  return (
    <div className="Collaterals">
      <Layout>
        <div className="col">
          <Card>
            <Statistic
              title="MXN"
              value={currencyFormat(mxnBalance)}
            ></Statistic>
            <Card.Meta
              title={intl.formatMessage({ id: "unused-amount" })}
              description={currencyFormat(unusedAmount)}
            ></Card.Meta>
            <Card.Meta
              title={intl.formatMessage({ id: "used-amount" })}
              description={currencyFormat(usedAmount)}
            ></Card.Meta>
          </Card>
          <div className="row actions">
            <Tooltip title={hasPendingTransactions && "Transacción pendiente"}>
              <Button
                disabled={hasPendingTransactions}
                icon={<MinusOutlined />}
                type="primary"
                onClick={() => setCashoutModalVisible(true)}
              >
                Retirar fondos
              </Button>
            </Tooltip>
            <Tooltip title={hasPendingTransactions && "Transacción pendiente"}>
              <Button
                disabled={hasPendingTransactions}
                icon={<PlusOutlined />}
                type="primary"
                onClick={() => setDepositModalVisible(true)}
              >
                Depositar fondos
              </Button>
            </Tooltip>
          </div>
          <Card>
            {Object.keys(balances || {})
              .filter((key) => key !== "__typename")
              .map((crypto) => (
                <Card.Grid key={crypto}>
                  <Statistic
                    title={
                      <div>
                        {getCryptoIcon(crypto)}
                        <label>{crypto}</label>
                      </div>
                    }
                    value={balances[crypto]}
                  ></Statistic>
                </Card.Grid>
              ))}
          </Card>
        </div>
        <Transactions />
        <DepositModal
          visible={depositModalVisible}
          onSuccess={() => {
            notification["success"]({
              message: intl.formatMessage({ id: "collateral-deposit-success" }),
            });
            setDepositModalVisible(false);
          }}
          setVisibility={setDepositModalVisible}
        />
        <CashoutModal
          visible={cashoutModalVisible}
          onSuccess={() => {
            notification["success"]({
              message: intl.formatMessage({ id: "collateral-cashout-success" }),
            });
            setCashoutModalVisible(false);
          }}
          setVisibility={setCashoutModalVisible}
        />
      </Layout>
    </div>
  );
}

export default injectIntl(Wallet);
