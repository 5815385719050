import React, { useState } from "react";
import { Popconfirm, Button } from "antd";
import { useHistory } from "react-router-dom";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { gql, useMutation } from "@apollo/client";

import "./DeclineCreditButton.scss";

const DeclineCreditButton = (props) => {
  const history = useHistory();

  const [confirmVisible, setConfirmVisible] = useState(false);

  const [declineCredit, { loading }] = useMutation(gql`
    mutation CreditSelfDecline($creditId: ID!) {
      creditSelfDecline(creditId: $creditId) {
        id
      }
    }
  `);

  const confirmDeclination = () => {
    declineCredit({
      variables: {
        creditId: props.creditId,
      },
    }).then((_) => {
      setConfirmVisible(false);
      history.push("/credito");
      window.location.reload();
    });
  };

  return (
    <div className="DeclineCreditButton">
      <Popconfirm
        title="¿Estás seguro?"
        visible={confirmVisible}
        icon={<QuestionCircleOutlined style={{ color: "red" }} />}
        onConfirm={confirmDeclination}
        onCancel={(_) => setConfirmVisible(false)}
      >
        <Button
          loading={loading}
          type="primary"
          onClick={(_) => setConfirmVisible(true)}
        >
          Declinar crédito
        </Button>
      </Popconfirm>
    </div>
  );
};

export default DeclineCreditButton;
