import { Card, Avatar } from "antd";
import Spin from "../Spin";
import UserEdit from "../UserEdit";

import "./User.scss";

function User({ credit, user, userLoading }) {
  if (!credit) {
    return <div />;
  }

  if (userLoading) return <Spin />;

  return (
    <div className="User">
      <Card>
        <div className="row">
          <Avatar size="large">
            {user?.firstName[0] || ""}
            {user?.lastName[0] || ""}
          </Avatar>
          <div className="col col-vertical-center spacer">
            <h3>
              {user?.firstName || ""} {user?.lastName || ""}
            </h3>
            <label>{user?.email || ""}</label>
            <label>{user?.phone || ""}</label>
          </div>
        </div>
        {user ? <UserEdit user={user} credit={credit} values={user} /> : null}
      </Card>
    </div>
  );
}

export default User;
