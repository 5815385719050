import { useEffect, useState } from "react";
import { gql, useQuery } from "@apollo/client";
import Spin from "../../../components/Spin";
import { Statistic, Card, Row, Col, Select, Button, Tooltip } from "antd";
import { Link, useHistory, useParams } from "react-router-dom";
import moment from "moment";
import PaymentModal from "../../../components/PaymentModal";
import { currencyFormat } from "../../../utils/format";

import "./Statistics.scss";
import { injectIntl } from "react-intl";

const CREDITS = gql`
  query CreditsByToken(
    $isQueued: Boolean
    $isPending: Boolean
    $isOpen: Boolean
    $status: CreditStatus
    $page: Int
    $pageSize: Int
  ) {
    creditsByToken(
      isQueued: $isQueued
      isPending: $isPending
      isOpen: $isOpen
      status: $status
      page: $page
      pageSize: $pageSize
    ) {
      results {
        id
        amount
        months
        monthlyPayment
        balance
        collateralRequired
        collateralUsed
        openingFee
        openingFeeIva
        paymentDates
        status
        paymentReports {
          id
          amount
          status
          principalDelta
          rechargeDelta
          rechargeIvaDelta
          interestDelta
          ivaDelta
          createdAt
        }
        pendingPaymentReports {
          id
        }
        deposit {
          reference
          comment
          depositedAt
          proof
        }
        payments {
          id
          scheduledDate
          scheduledAmount
          scheduledPrincipal
          scheduledInterest
          scheduledIva
          scheduledRecharge
          scheduledRechargeIva
          scheduledBalance
          isPaid
          paymentDate
          paymentAmount
          principal
          interest
          iva
          recharge
          rechargeIva
          isManual
        }
        nextPaymentDate
        nextPaymentData {
          amountToSettle
          pendingAmount
          recharge
          rechargeIva
        }
        nextPaymentIndex
      }
    }
  }
`;

function Statistics(props) {
  const { get, intl } = props;
  const [paymentModalVisibility, setPaymentModalVisibility] = useState(false);

  const { loading, error, data } = useQuery(CREDITS, {
    pollInterval: 60000,
  });

  const closeModal = () => setPaymentModalVisibility(false);

  const { Option } = Select;

  const history = useHistory();
  const { id } = useParams();

  let creditSelected =
    (data?.creditsByToken.results || []).find((item) => item.id === id) || {};

  let hasPendingPayments = !!(creditSelected?.pendingPaymentReports || [])
    .length;

  let paymentButtonDisplay =
    creditSelected?.status === "STARTED" ||
    creditSelected?.status === "PAYMENTPENDING";

  useEffect(() => {
    const creditId = data?.creditsByToken.results[0]?.id;
    if (!id && creditId) {
      history.push(`/credito/${creditId}`);
      window.location.reload();
    }
    if (!Object.keys(creditSelected).length && id && !loading) {
      history.push(`/credito`);
      window.location.reload();
    }
    if (data) {
      get(creditSelected);
    }
  }, [id, data]); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) return <Spin />;

  if (error) return `Error! ${error.message}`;

  const statusColor = (status) => {
    switch (status) {
      case "STARTED":
        return "#00bd00";
      case "DECLINED":
        return "red";
      default:
        return "#4B7EFF";
    }
  };

  const nextPaymentDate = moment(creditSelected?.nextPaymentDate);

  const paymentDateisAfterToday = moment().isAfter(nextPaymentDate);

  return (
    <div className={`Statistics ${!creditSelected?.id && "hidden"}`}>
      {paymentModalVisibility && (
        <PaymentModal
          credit={creditSelected}
          visible={paymentModalVisibility}
          onSuccess={closeModal}
          onCancel={closeModal}
        />
      )}
      {data?.creditsByToken.results.length > 1 && (
        <Select
          style={{ width: "100%" }}
          placeholder="Ver otros créditos"
          dropdownClassName="statistics-dropdown"
        >
          {data?.creditsByToken.results.map((credit, index) => (
            <Option key={index} value={credit?.id}>
              <Link
                to={`/credito/${credit.id}`}
                style={{ color: statusColor(credit.status) }}
              >
                {currencyFormat(credit.balance)} (Pago mensual:{" "}
                {currencyFormat(credit.monthlyPayment)})
              </Link>
            </Option>
          ))}
        </Select>
      )}
      <Row span={24} gutter={[24, 36]}>
        <Col md={{ span: 24 }} lg={{ span: 8 }}>
          <Card className="status-content">
            <Statistic
              title={intl.formatMessage({
                id: "balance",
              })}
              value={currencyFormat(
                creditSelected?.nextPaymentData?.amountToSettle
              )}
              precision={2}
            />
          </Card>
        </Col>
        <Col md={{ span: 24 }} lg={{ span: 8 }}>
          <Card className="status-content">
            <div className="payment-status-content">
              {creditSelected?.nextPaymentDate &&
                creditSelected?.status === "STARTED" && (
                  <Statistic
                    className={paymentDateisAfterToday && "warning"}
                    title={`${
                      paymentDateisAfterToday
                        ? intl.formatMessage({
                            id: "due-at",
                          })
                        : intl.formatMessage({
                            id: "to-pay-at",
                          })
                    } ${nextPaymentDate.format("DD/MM/YYYY")}`}
                    value={currencyFormat(
                      creditSelected?.nextPaymentData?.pendingAmount
                    )}
                  />
                )}
              {creditSelected?.status && creditSelected?.status !== "STARTED" && (
                <Statistic
                  title={intl.formatMessage({
                    id: "status",
                  })}
                  value={intl.formatMessage({
                    id: `creditStatus.${creditSelected?.status}`,
                  })}
                />
              )}
              <div
                style={{
                  backgroundColor: "#FF0000",
                  marginLeft: "2em",
                  justifyContent: "flex-end",
                  justifyItems: "flex-end",
                  alignContent: "flex-end",
                  alignItems: "flex-end",
                }}
              ></div>
              {paymentButtonDisplay && (
                <Tooltip
                  title={hasPendingPayments && "Registro de pago pendiente"}
                  className="tooltip"
                >
                  <Button
                    type="primary"
                    enabled={!hasPendingPayments}
                    onClick={(_) => setPaymentModalVisibility(true)}
                  >
                    PAGAR
                  </Button>
                </Tooltip>
              )}
            </div>
          </Card>
        </Col>
        <Col md={{ span: 24 }} lg={{ span: 8 }}>
          <Card className="status-content">
            <Statistic
              title={intl.formatMessage({
                id: "advance",
              })}
              value={`${creditSelected?.nextPaymentIndex || 0}/${
                (creditSelected?.payments || []).length
              }`}
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default injectIntl(Statistics);
