import { useState } from "react";
import Status from "./Status";
import Layout from "../../components/Layout";

import "./Dashboard.scss";

function Dashboard() {
  const [creditSelected, setCreditSelected] = useState({});
  const [user, setUser] = useState({});
  return (
    <div className="Dashboard">
      <Layout getUser={setUser}>
        <Status get={setCreditSelected} credit={creditSelected} user={user} />
        {!creditSelected?.id && <></>}
      </Layout>
    </div>
  );
}

export default Dashboard;
