import React, { useState } from "react";
import { Table, Tooltip } from "antd";
import { WarningOutlined } from "@ant-design/icons";
import moment from "moment";
import { currencyFormat } from "../../../utils/format";
import "./Payments.scss";

function Payments(props) {
  const { nextPaymentIndex, nextPaymentData } = props;

  const [pageNumber, setPageNumber] = useState(1);

  const renderStatusWrapper = (record, child) => {
    const isDelayed = moment(record.scheduledDate).isBefore(new Date());

    if (record.isPaid) {
      return <span className="paid-row">{child}</span>;
    } else if (isDelayed) {
      return <span className="delayed-row">{child}</span>;
    }
    return <span>{child}</span>;
  };

  const pageSize = 10;

  const columns = [
    {
      dataIndex: "index",
      key: "index",
      render: (_, __, index) => index + 1 + pageSize * (pageNumber - 1),
    },
    {
      dataIndex: "status",
      key: "status",
      render: (_, record) => {
        const isDelayed =
          moment(record.scheduledDate).isBefore(new Date()) && !record.isPaid;

        return isDelayed ? <WarningOutlined /> : null;
      },
    },
    {
      title: "Fecha",
      dataIndex: "scheduledDate",
      key: "scheduledDate",
      render: (value, record) =>
        renderStatusWrapper(record, moment(value).format("DD/MM/YYYY")),
    },
    {
      title: "Saldo Inicial",
      render: (_, record) =>
        renderStatusWrapper(
          record,
          currencyFormat(
            record.isPaid ? record.paymentAmount : record.scheduledBalance
          )
        ),
    },
    {
      title: "Capital",
      render: (_, record) =>
        renderStatusWrapper(record, currencyFormat(record.scheduledPrincipal)),
    },
    {
      title: "Intereses",
      render: (_, record) =>
        renderStatusWrapper(
          record,
          <Tooltip
            title={
              record.isPaid && (
                <div>
                  Interes: {record.scheduledInterest}
                  <br />
                  IVA: {record.scheduledIva}
                </div>
              )
            }
          >
            {currencyFormat(record.scheduledInterest + record.scheduledIva)}
          </Tooltip>
        ),
    },
    {
      title: "Recargo",
      render: (_, record, index) => {
        if (index > nextPaymentIndex) {
          return renderStatusWrapper(
            record,
            currencyFormat(
              record.scheduledRecharge + record.scheduledRechargeIva
            )
          );
        }
        if (index < nextPaymentIndex) {
          return renderStatusWrapper(
            record,
            <Tooltip
              title={
                <div>
                  Recargo: {record.recharge}
                  <br />
                  IVA: {record.rechargeIva}
                </div>
              }
            >
              {currencyFormat(record.recharge + record.rechargeIva)}
            </Tooltip>
          );
        }
        return renderStatusWrapper(
          record,
          currencyFormat(
            nextPaymentData?.recharge + nextPaymentData?.rechargeIva || 0
          )
        );
      },
    },
    {
      title: "Monto pagado",
      render: (_, record) =>
        renderStatusWrapper(record, currencyFormat(record.paymentAmount)),
    },
    {
      title: "Monto por pagar",
      render: (_, record, index) => {
        if (nextPaymentIndex === index) {
          return renderStatusWrapper(
            record,
            currencyFormat(nextPaymentData?.pendingAmount)
          );
        }
        return renderStatusWrapper(
          record,
          currencyFormat(
            Math.max(record.scheduledAmount - record.paymentAmount, 0)
          )
        );
      },
    },
    {
      title: "Abono a capital",
      render: (value, record) =>
        renderStatusWrapper(record, currencyFormat(record.principal)),
    },
  ];

  return (
    <div className="Payments">
      <Table
        rowKey={"id"}
        current={pageNumber}
        onChange={(pageProps) => {
          setPageNumber(pageProps.current);
        }}
        pagination={true}
        columns={columns}
        title={() => "Tabla de amortización"}
        dataSource={props.data}
        scroll={{ x: 400 }}
      />
    </div>
  );
}

export default Payments;
