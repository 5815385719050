import { Form, Input } from "antd";
import { injectIntl } from "react-intl";
import CollateralModal from "../../../components/CollateralModal";
import "./DepositModal.scss";

function DepositModal(props) {
  const [form] = Form.useForm();

  const { setVisibility } = props;

  const onFinish = () => {
    setVisibility(false);
  };

  const onCancel = () => {
    setVisibility(false);
  };

  return (
    <CollateralModal
      title="Depositar fondos"
      hideAmountRequired={false}
      onCancel={onCancel}
      onOk={form.submit}
      {...props}
    >
      <Form onFinish={onFinish} form={form} layout="vertical">
        <Form.Item
          name="currency"
          hidden
          rules={[
            {
              message: "Por favor ingresa una moneda",
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </CollateralModal>
  );
}

export default injectIntl(DepositModal);
