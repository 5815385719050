import { useEffect, useState } from "react";
import { Table } from "antd";
import { gql, useLazyQuery } from "@apollo/client";
import getCryptoIcon from "../../constants/crypto-icons";

import "./Transactions.scss";

const COLLATERAL_TRANSACTIONS = gql`
  query CollateralTransactionsByToken(
    $status: CollateralTransactionStatus
    $type: CollateralTransactionType
    $page: Int
    $pageSize: Int
  ) {
    collateralTransactionsByToken(
      status: $status
      type: $type
      page: $page
      pageSize: $pageSize
    ) {
      info {
        prev
        next
        count
        pages
      }
      results {
        id
        currency
        amount
        proof
        transactionHash
        status
        type
      }
    }
  }
`;

function Transactions() {
  const [getTransactions, { loading, data }] = useLazyQuery(
    COLLATERAL_TRANSACTIONS
  );

  const [page, setPage] = useState(1);

  const pageSize = 10;

  const columns = [
    {
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Monto",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Moneda",
      dataIndex: "currency",
      key: "currency",
      render: (currency) => (
        <div>
          {getCryptoIcon(currency)} {currency}
        </div>
      ),
    },
    {
      title: "Hash",
      dataIndex: "transactionHash",
      key: "transactionHash",
    },
    {
      title: "Tipo",
      dataIndex: "type",
      key: "type",
    },
  ];

  useEffect(
    () =>
      getTransactions({
        variables: {
          page,
          pageSize,
        },
      }),
    [page, getTransactions]
  );

  const onChange = (pagination) => {
    setPage(pagination.current);
  };

  return (
    <div className="Transactions">
      <Table
        rowKey={"id"}
        loading={loading}
        columns={columns}
        title={() => "Transacciones"}
        pagination={{
          pageSize,
        }}
        dataSource={data?.collateralTransactionsByToken.results}
        onChange={onChange}
      />
    </div>
  );
}

export default Transactions;
