import React, { useState, useEffect } from "react";
import { injectIntl } from "react-intl";
import { Form, Button, Input, Select, notification } from "antd";
import FileUpload from "../FileUpload";
import { gql, useMutation } from "@apollo/client";

import "./UserEdit.scss";
import FormItem from "antd/lib/form/FormItem";

const { Option } = Select;

const USER_EDIT = gql`
  mutation userEdit($userToEdit: UserToEdit!) {
    userEdit(userToEdit: $userToEdit) {
      id
    }
  }
`;

function UserEdit(props) {
  const [userEditMutation, { loading }] = useMutation(USER_EDIT, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    onCompleted: () => {
      notification.open({
        message: "Información enviada",
        description: "La información ha sido enviada correctamente",
        duration: 5,
        placement: "bottomRight",
      });
    },
    onError: () => {
      notification.error({
        message: "Información NO enviada",
        description: "La información no ha podido ser enviada correctamente",
        duration: 5,
        placement: "bottomRight",
      });
    },
  });

  const [userToEdit, setForm] = useState({});

  const [hasEmptyInformation, setHasEmptyInformation] = useState(false);

  const [hasEmptyDocuments, setHasEmptyDocuments] = useState(false);

  const onChange = (value) => {
    setForm({
      ...userToEdit,
      ...value,
    });
  };

  const onFinish = () => {
    userEditMutation({
      variables: {
        userToEdit,
      },
    });
  };

  const { intl, values, credit, user } = props;

  useEffect(
    (_) => {
      let isEmptyInformation = false;
      Object.keys(values).forEach((key) => {
        if (!isEmptyInformation) {
          isEmptyInformation = !values[key];
        }
      });

      let isEmptyDocuments =
        credit.status !== "STARTED" &&
        (!user["idCard"][0] ||
          !user["bankStatement"][0] ||
          !user["addressDocument"][0]);
      setHasEmptyInformation(isEmptyInformation);
      setHasEmptyDocuments(isEmptyDocuments);
    },
    [values, user, credit.status]
  );

  return (
    <div className="UserEdit">
      <div className="profile-information-status">
        {hasEmptyInformation
          ? "Completa tu información"
          : hasEmptyDocuments
          ? "Envía tus documentos"
          : "Información recibida y completa"}
      </div>
      <Form
        layout="vertical"
        name="signUp"
        onValuesChange={onChange}
        onFinish={onFinish}
      >
        <div className="AuthForm row">
          <div
            className="col col-vertical-left"
            style={{ margin: "0 2em 0 2em" }}
          >
            <div className="profile-information-title">
              {intl.formatMessage({ id: "your-information-label-1" })}
            </div>
            <Form.Item
              label={intl.formatMessage({ id: "address-label" })}
              name="address"
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({
                    id: "address-required-error",
                  }),
                },
              ]}
              initialValue={values?.address}
            >
              <Input
                placeholder={intl.formatMessage({
                  id: "address-placeholder",
                })}
                size="large"
              />
            </Form.Item>
            <Form.Item
              label={intl.formatMessage({ id: "zipCode-label" })}
              name="zipCode"
              rules={[
                {
                  required: true,
                  pattern: /^[0-9]{5}$/,
                  message: intl.formatMessage({
                    id: "zipCode-required-error",
                  }),
                },
              ]}
              initialValue={values?.zipCode}
            >
              <Input
                placeholder={intl.formatMessage({
                  id: "zipCode-placeholder",
                })}
                size="large"
              />
            </Form.Item>
            <Form.Item
              label={intl.formatMessage({ id: "rfc-label" })}
              name="rfc"
              rules={[
                {
                  required: true,
                  pattern:
                    /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/,
                  min: 13,
                  message: intl.formatMessage({ id: "rfc-required-error" }),
                },
              ]}
              initialValue={values?.rfc}
            >
              <Input
                placeholder={intl.formatMessage({ id: "rfc-placeholder" })}
                size="large"
              />
            </Form.Item>
            <Form.Item
              label={intl.formatMessage({ id: "gender-label" })}
              name="gender"
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({
                    id: "gender-required-error",
                  }),
                },
              ]}
              initialValue={values?.gender}
            >
              <Select
                placeholder={intl.formatMessage({
                  id: "gender-placeholder",
                })}
                size="large"
              >
                <Option value="MALE">
                  {intl.formatMessage({ id: "male" })}
                </Option>
                <Option value="FEMALE">
                  {intl.formatMessage({ id: "female" })}
                </Option>
                <Option value="UNKNOWN">
                  {intl.formatMessage({ id: "gender-unknown" })}
                </Option>
              </Select>
            </Form.Item>
            <Form.Item
              label={intl.formatMessage({ id: "phone-label" })}
              name="phone"
              rules={[
                {
                  required: true,
                  pattern: /^[0-9]{10}$/,
                  message: intl.formatMessage({
                    id: "phone-required-error",
                  }),
                },
              ]}
              initialValue={values?.phone}
            >
              <Input
                placeholder={intl.formatMessage({
                  id: "phone-placeholder",
                })}
                size="large"
              />
            </Form.Item>
            <Form.Item
              label={intl.formatMessage({ id: "bankAccountClabe-label" })}
              name="bankAccountClabe"
              rules={[
                {
                  min: 18,
                  required: true,
                  pattern: /^[0-9]{18}$/,
                  message: intl.formatMessage({
                    id: "bankAccountClabe-required-error",
                  }),
                },
              ]}
              initialValue={values?.bankAccountClabe}
            >
              <Input
                placeholder={intl.formatMessage({
                  id: "bankAccountClabe-placeholder",
                })}
                size="large"
              />
            </Form.Item>
          </div>
          <div
            className="col col-vertical-left"
            style={{ margin: "0 2em 0 2em" }}
          >
            <div className="profile-information-title">
              {intl.formatMessage({ id: "your-information-label-2" })}
            </div>

            {credit.status !== "STARTED" && (
              <div>
                <FormItem>
                  <FileUpload
                    label="Envía tu INE u otro documento de identidad oficial con foto. Todos los datos de la identificación deben ser legibles, y deben verse las 4 esquinas del documento."
                    name="idCard"
                    value={user["idCard"][0]}
                    maxCount={1}
                    previewFile
                    progress
                    status
                  />
                </FormItem>
                <FormItem>
                  <FileUpload
                    label="Envía un estado de cuenta bancario en el que se lea claramente tu Clabe."
                    name="bankStatement"
                    value={user["bankStatement"][0]}
                  />
                </FormItem>
                <FormItem>
                  <FileUpload
                    label="Envía un recibo de servicios públicos de los últimos 3 meses. Todos los datos deben ser legibles y deben verse las 4 esquinas del documento."
                    name="addressDocument"
                    value={user["addressDocument"][0]}
                  />
                </FormItem>
              </div>
            )}
            <Form.Item>
              <Button loading={loading} type="primary" htmlType="submit">
                {intl.formatMessage({ id: "continue-button" })}
              </Button>
            </Form.Item>
          </div>
        </div>
      </Form>
    </div>
  );
}

export default injectIntl(UserEdit);
