import BTC from "../assets/images/btc.svg";
import ETH from "../assets/images/eth.svg";
import BCH from "../assets/images/bch.svg";
import DOT from "../assets/images/dot.svg";
import XRP from "../assets/images/xrp.svg";
import ADA from "../assets/images/ada.svg";
import LINK from "../assets/images/link.svg";
import USDT from "../assets/images/usdt.svg";
import XLM from "../assets/images/xlm.svg";

const icons = {
  BTC,
  BCH,
  ETH,
  DOT,
  XRP,
  ADA,
  LINK,
  USDT,
  XLM,
};

const getIcon = (name) => {
  return <img alt={`${name}-coin`} src={icons[name]} />;
};

export default getIcon;
