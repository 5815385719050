import React from "react";
import { injectIntl } from "react-intl";
import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "../../assets/images/logo-black.svg";

import "./Header.scss";

const Header = () => (
  <header>
    <div className="wrapper content-wrapper">
      <nav>
        <Link to="/">
          <Logo />
        </Link>
      </nav>
    </div>
  </header>
);

export default injectIntl(Header);
