import React, { useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import { Tabs, Button } from "antd";
import { gql, useQuery } from "@apollo/client";
import Calculator from "../../components/Calculator";
import Spin from "../../components/Spin";
import Credits from "../../components/Credits";
import CreditAvailable from "../../components/CreditAvailable";
import Resume from "./Resume";
import Layout from "../../components/Layout";
import { RightSquareOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";

import "./CreditRequest.scss";

const PRODUCTS = gql`
  query Products($page: Int!, $pageSize: Int!) {
    products(page: $page, pageSize: $pageSize) {
      results {
        id
        isActive
        name
        description
        collateralRequired
        openingFee
        interestRate
        minAmount
        maxAmount
        minPayments
        maxPayments
        promptPaymentSurcharge
        createdAt
        updatedAt
      }
    }
  }
`;

const { TabPane } = Tabs;

function CreditRequest(props) {
  const useParamsQuery = () => new URLSearchParams(useLocation().search);
  const query = useParamsQuery();
  const amountParam = Number(query.get("amount"));
  const monthsParam = Number(query.get("months"));

  const [form, setForm] = useState();

  const [loadingAvailableCredit, setLoadingAvailableCredit] = useState(true);

  const [loadingOnProgressCredits, setLoadingOnProgressCredits] =
    useState(true);

  const [creditAvailable, setCreditAvailable] = useState();

  const [creditsOnProgress, setCreditsOnProgress] = useState();

  const [activeKey, setActiveKey] = useState(1);

  const nextStep = () => setActiveKey(activeKey + 1);

  const updateForm = (value) => {
    setForm({
      ...form,
      ...value,
    });

    nextStep();
  };

  const history = useHistory();

  const { intl } = props;

  const loanAmount =
    amountParam || Number(sessionStorage.getItem("amount")) || null;

  const loanMonths =
    monthsParam || Number(sessionStorage.getItem("months")) || 6;

  const { loading, error, data } = useQuery(PRODUCTS, {
    variables: {
      page: 1,
      pageSize: 10,
    },
  });

  const isLoadingPermissions =
    loadingAvailableCredit && loadingOnProgressCredits;

  const canRequestCredit = () => {
    if (!isLoadingPermissions) {
      const userWantsNewCredit = window.location.search.indexOf("?new") > -1;

      if (
        (!userWantsNewCredit, creditsOnProgress) ||
        (userWantsNewCredit && creditsOnProgress && !creditAvailable)
      ) {
        history.push("/dashboard");
      }
    }
  };

  useEffect(canRequestCredit, [
    creditsOnProgress,
    creditAvailable,
    history,
    isLoadingPermissions,
  ]);

  if (error) return `Error! ${error.message}`;

  const productSelected = data?.products.results[0] || {};

  const backButton =
    activeKey !== 1 ? (
      <div>
        Volver
        <Button
          onClick={(_) => setActiveKey(1)}
          type="primary"
          ghost
          size="large"
          icon={<RightSquareOutlined />}
        />
      </div>
    ) : (
      <div>
        Salir
        <Button
          onClick={(_) => history.push("/dashboard")}
          type="primary"
          ghost
          size="large"
          icon={<RightSquareOutlined />}
        />
      </div>
    );

  return (
    <Layout disabledCreditButton>
      <div className="CreditRequest Form col">
        {(loading || isLoadingPermissions) && <Spin />}
        <Credits
          canRequestCredit={(value) => {
            setLoadingOnProgressCredits(false);
            setCreditsOnProgress(value);
          }}
        />
        <CreditAvailable
          canRequestCredit={(value) => {
            setLoadingAvailableCredit(false);
            setCreditAvailable(value);
          }}
        />

        <div className="content-wrapper col">
          <Tabs
            centered
            activeKey={activeKey.toString()}
            tabBarExtraContent={{
              right: backButton,
            }}
          >
            <TabPane
              tab={intl.formatMessage({ id: "credit-calculator-tab" })}
              key="1"
            >
              <Calculator
                product={productSelected}
                loanAmount={loanAmount}
                loanMonths={loanMonths}
                tooltipVisible={activeKey === 1}
                formProps={{
                  onFinish: updateForm,
                }}
              />
            </TabPane>
            <TabPane
              tab={intl.formatMessage({ id: "credit-resume-tab" })}
              key="2"
            >
              <Resume value={form} product={productSelected} />
            </TabPane>
          </Tabs>
        </div>
      </div>
    </Layout>
  );
}

export default injectIntl(CreditRequest);
