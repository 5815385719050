import React from "react";
import { Modal, Form, Input } from "antd";
import { currencyFormat } from "../../utils/format";
import moment from "moment";
import FileUpload from "../FileUpload";
import { gql, useMutation } from "@apollo/client";
import CurrencyInput from "../CurrencyInput";

import "./PaymentModal.scss";

const ADD_PAYMENT = gql`
  mutation CreditAddPaymentReport(
    $creditId: ID!
    $amount: Float!
    $proof: String!
    $reference: String!
  ) {
    creditAddPaymentReport(
      creditId: $creditId
      amount: $amount
      proof: $proof
      reference: $reference
    ) {
      id
    }
  }
`;

const PaymentModal = (props) => {
  const [form] = Form.useForm();

  const [creditAddPaymentReport, { loading }] = useMutation(ADD_PAYMENT);

  const { credit } = props;

  const onFinish = () => {
    creditAddPaymentReport({
      variables: {
        ...form.getFieldsValue(),
        creditId: credit.id,
      },
    }).then(() => {
      form.resetFields();
      props.onSuccess();
    });
  };

  const onCancel = () => {
    form.resetFields();
    props.onCancel();
  };

  const onProofUploaded = (proof) => {
    form.setFieldsValue({
      ...form.getFieldsValue(),
      proof,
    });
  };

  return (
    <Modal
      {...props}
      title="Reportar Pago"
      onOk={form.submit}
      onCancel={onCancel}
      confirmLoading={loading}
    >
      <Form onFinish={onFinish} form={form} layout="vertical">
        <Form.Item label="Monto para liquidar:">
          <span className="ant-form-text">
            {currencyFormat(credit.nextPaymentData.amountToSettle)}
          </span>
        </Form.Item>
        <Form.Item label="Pago requerido:">
          <span className="ant-form-text">
            {currencyFormat(credit.monthlyPayment)}
          </span>
        </Form.Item>
        <Form.Item label="Fecha limite:">
          <span className="ant-form-text">
            {moment(
              credit.payments.find((payment) => !payment.isPaid)?.scheduledDate
            ).format("DD/MM/YYYY")}
          </span>
        </Form.Item>
        <Form.Item
          name="amount"
          rules={[
            {
              required: true,
              message: "Por favor ingresa un monto",
            },
          ]}
        >
          <CurrencyInput placeholder="Monto del pago" />
        </Form.Item>
        <Form.Item
          name="reference"
          rules={[
            {
              required: true,
              message: "Por favor ingresa el número de referencia",
            },
          ]}
        >
          <Input placeholder="Referencia" />
        </Form.Item>
        <Form.Item
          label="Comprobante"
          name="proof"
          rules={[
            {
              required: true,
              message: "Por favor adjunta el comprobante",
            },
          ]}
        >
          <FileUpload
            draggable
            label="Envía el comprobante de pago"
            name="proof"
            value={form.getFieldsValue()?.proof}
            getFileUrl={onProofUploaded}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default PaymentModal;
