import { useEffect, useState } from "react";
import { gql, useQuery } from "@apollo/client";
import Payments from "../Payments";
import Spin from "../../../components/Spin";
import { Card, Select, Button } from "antd";
import { Link, useHistory, useParams } from "react-router-dom";
import CollateralModal from "../../../components/CollateralModal";
import { currencyFormat } from "../../../utils/format";

import "./CreditInfo.scss";
import { injectIntl } from "react-intl";
import DeclineCreditButton from "../../../components/DeclineCreditButton";
import PaymentReports from "../PaymentReports";

const CREDITS = gql`
  query CreditsByToken(
    $isQueued: Boolean
    $isPending: Boolean
    $isOpen: Boolean
    $status: CreditStatus
    $page: Int
    $pageSize: Int
  ) {
    creditsByToken(
      isQueued: $isQueued
      isPending: $isPending
      isOpen: $isOpen
      status: $status
      page: $page
      pageSize: $pageSize
    ) {
      results {
        id
        amount
        months
        monthlyPayment
        balance
        collateralRequired
        collateralUsed
        openingFee
        openingFeeIva
        paymentDates
        status
        paymentReports {
          id
          amount
          status
          principalDelta
          rechargeDelta
          rechargeIvaDelta
          interestDelta
          ivaDelta
          createdAt
        }
        pendingPaymentReports {
          id
        }
        deposit {
          reference
          comment
          depositedAt
          proof
        }
        payments {
          id
          scheduledDate
          scheduledAmount
          scheduledPrincipal
          scheduledInterest
          scheduledIva
          scheduledRecharge
          scheduledRechargeIva
          scheduledBalance
          isPaid
          paymentDate
          paymentAmount
          principal
          interest
          iva
          recharge
          rechargeIva
          isManual
        }
        nextPaymentDate
        nextPaymentData {
          amountToSettle
          pendingAmount
          recharge
          rechargeIva
        }
        nextPaymentIndex
      }
    }
  }
`;

function CreditInfo(props) {
  const [collateralModalVisible, setCollateralModalVisible] = useState(false);

  const { get } = props;

  const { loading, error, data } = useQuery(CREDITS, {
    pollInterval: 60000,
  });

  const { Option } = Select;

  const { id } = useParams();
  const history = useHistory();

  let creditSelected =
    (data?.creditsByToken.results || []).find((item) => item.id === id) || {};

  useEffect(() => {
    const creditId = data?.creditsByToken.results[0]?.id;
    if (!id && creditId) {
      history.push(`/credito/${creditId}`);
      window.location.reload();
    }
    if (!Object.keys(creditSelected).length && id && !loading) {
      history.push(`/credito`);
      window.location.reload();
    }
    if (data) {
      get(creditSelected);
    }
  }, [id, data]); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) return <Spin />;

  if (error) return `Error! ${error.message}`;

  const statusColor = (status) => {
    switch (status) {
      case "STARTED":
        return "#00bd00";
      case "DECLINED":
        return "red";
      default:
        return "#4B7EFF";
    }
  };

  return (
    <div className={`CreditInfo ${!creditSelected?.id && "hidden"}`}>
      {data?.creditsByToken.results.length > 1 && (
        <Select
          style={{ width: "100%" }}
          placeholder="Ver otros créditos"
          dropdownClassName="credits-dropdown"
        >
          {data?.creditsByToken.results.map((credit, index) => (
            <Option key={index} value={credit?.id}>
              <Link
                to={`/credito/${credit.id}`}
                style={{ color: statusColor(credit.status) }}
              >
                {currencyFormat(credit.balance)} (Pago mensual:{" "}
                {currencyFormat(credit.monthlyPayment)})
              </Link>
            </Option>
          ))}
        </Select>
      )}
      {(creditSelected?.status === "STARTED" ||
        creditSelected?.status === "PAYMENTPENDING" ||
        creditSelected?.status === "FINISHEDPAID" ||
        creditSelected?.status === "FINISHEDUNPAID") && (
        <Payments
          data={creditSelected?.payments}
          nextPaymentIndex={creditSelected?.nextPaymentIndex}
          nextPaymentData={creditSelected?.nextPaymentData}
        />
      )}
      {creditSelected?.status === "STARTED" &&
        !!(creditSelected?.paymentReports || []).length && (
          <PaymentReports data={creditSelected?.paymentReports} />
        )}
      {creditSelected?.status === "APPROVED" && (
        <Card className="disclaimer">
          <h3>
            Tu crédito está aprobado, te haremos el depósito en las próximas 72
            horas.
          </h3>
        </Card>
      )}
      {creditSelected?.status === "COLLATERALPENDING" && (
        <Card className="collateral-pending">
          <Button
            type="primary"
            onClick={(_) => setCollateralModalVisible(true)}
          >
            Depositar colateral
          </Button>
          {creditSelected?.status === "EMAILVERIFICATIONPENDING" ||
          creditSelected?.status === "KYCPENDING" ||
          creditSelected?.status === "COLLATERALPENDING" ? (
            <DeclineCreditButton creditId={creditSelected?.id} />
          ) : null}
          <CollateralModal
            collateralRequired={creditSelected?.collateralRequired}
            visible={collateralModalVisible}
            onCancel={() => setCollateralModalVisible(false)}
            onOk={() => setCollateralModalVisible(false)}
          />
        </Card>
      )}
    </div>
  );
}

export default injectIntl(CreditInfo);
