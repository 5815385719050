import { useEffect, useState } from "react";
import Router from "./Router";
import Intl from "./i18n/Intl";
import { Button, notification } from "antd";
import ApolloProvider from "./utils/Apollo";
import "./App.less";

function App() {
  const [loaded, setLoaded] = useState(false);
  const getAmount = () => {
    const { search: queryString } = window.location;
    const urlParams = new URLSearchParams(queryString);
    const amount = urlParams.get("amount");
    const months = urlParams.get("months");

    amount && sessionStorage.setItem("amount", amount);

    months && sessionStorage.setItem("months", months);

    setLoaded(true);
  };

  const onClose = () => {
    localStorage.setItem("cookies-accepted", true);
    notification.close("cookies-disclaimer");
  };

  const btn = (
    <Button type="primary" size="small" onClick={onClose}>
      Aceptar
    </Button>
  );

  const cookiesDisclaimer = () => {
    if (!localStorage.getItem("cookies-accepted")) {
      notification.open({
        message: "Privacidad",
        description: (
          <span>
            Este sitio utiliza cookies para mejorar tu experiencia. Al continuar
            navegando, aceptas su uso. Haz click aquí para ver nuestra{" "}
            <a href="/terminos-y-condiciones">política de privacidad</a>
          </span>
        ),
        btn,
        key: "cookies-disclaimer",
        duration: 0,
        placement: "bottomLeft",
        onClose,
      });
    }
  };

  useEffect(cookiesDisclaimer, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(getAmount, []);

  return (
    <div className="App col">
      <ApolloProvider>
        <Intl>{loaded && <Router />}</Intl>
      </ApolloProvider>
    </div>
  );
}

export default App;
