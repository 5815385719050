import React, { useState, useEffect } from "react";
import { Modal, Select, Form, notification } from "antd";
import { gql, useQuery, useLazyQuery } from "@apollo/client";
import { CopyOutlined } from "@ant-design/icons";

import { CopyToClipboard } from "react-copy-to-clipboard";
import getCryptoIcon from "../../constants/crypto-icons";

import "./CollateralModal.scss";
import { injectIntl } from "react-intl";
import { currencyFormat } from "../../utils/format";

const CRYPTOS_ALLOWED = gql`
  query {
    cryptosAllowed
  }
`;

const USER = gql`
  query UserByToken {
    userByToken {
      id
      bannerStatus
    }
  }
`;

const COLLATERALL_CALCULATE = gql`
  query CollateralTransactionCalculate(
    $mxnAmount: Float!
    $crypto: CryptosAllowed!
  ) {
    collateralTransactionCalculate(mxnAmount: $mxnAmount, crypto: $crypto) {
      wallet
      amount
    }
  }
`;

const CollateralModal = (props) => {
  const {
    intl,
    collateralRequired,
    children,
    hideAmountRequired,
    hideWallet,
    onChange,
    loading,
  } = props;

  const { loading: cryptosLoading, data } = useQuery(CRYPTOS_ALLOWED);

  const [
    collateralCalculate,
    { loading: calculateLoading, data: collateralData },
  ] = useLazyQuery(COLLATERALL_CALCULATE);

  const { loading: userLoading, data: userData } = useQuery(USER, {
    refetchInterval: 1000,
    pollInterval: 60000,
    onCompleted: () => {
      setBannerStatus(userData.userByToken?.bannerStatus);
    },
  });

  console.log ("Collateral Data", collateralData?.collateralTransactionCalculate);

  const [bannerStatus, setBannerStatus] = useState("");

  const [cryptoSelected, setCryptoSelected] = useState("BTC");

  const { wallet, amount } =
    collateralData?.collateralTransactionCalculate || {};

  const truncatedAmount = (amount || 0);

  useEffect(() => {
    collateralCalculate({
      variables: {
        mxnAmount: collateralRequired || 0,
        crypto: cryptoSelected,
      },
    });
  }, [cryptoSelected, collateralCalculate, collateralRequired]);

  useEffect(() => {
    if (onChange) {
      if (props.visible) {
        onChange({
          cryptoSelected,
        });
      } else {
        onChange({
          cryptoSelected: null,
        });
      }
    }
    // eslint-disable-next-line
  }, [cryptoSelected, props.visible]);

  const copiedToClipboardNotification = () => {
    notification["success"]({
      message: intl.formatMessage({ id: "wallet-copied-to-clipboard" }),
    });
  };

  const { Option } = Select;

  const options = data?.cryptosAllowed || [];

  return (
    <Modal
      title="Colateral pendiente"
      {...props}
      className="CollateralModal"
      confirmLoading={
        loading || cryptosLoading || calculateLoading || userLoading
      }
    >
      <Form.Item>
        <Select
          defaultValue={cryptoSelected}
          size="large"
          dropdownClassName="currency-dropdown"
          className="currency-select"
          onChange={setCryptoSelected}
        >
          {options.map((coin, index) => (
            <Option key={index} value={coin}>
              {getCryptoIcon(coin)}
              <label>{coin}</label>
            </Option>
          ))}
        </Select>
      </Form.Item>
      {!hideAmountRequired && bannerStatus === "COLLATERALPENDING" && (
        <Form.Item label={intl.formatMessage({ id: "amount-required" })}>
          <span className="ant-form-text">
            {calculateLoading? '-': `${truncatedAmount} ${currencyFormat(collateralRequired)} MXN`}
          </span>
          <br />
        </Form.Item>
      )}
      <Form.Item>
        <span className="ant-form-text">
          {intl
            .formatMessage({ id: "collateral-coin-confirm" })
            .replaceAll("coin", cryptoSelected)}
        </span>
      </Form.Item>

      {!hideWallet && (
        <Form.Item>
          <CopyToClipboard text={wallet} onCopy={copiedToClipboardNotification}>
            <div className="wallet row">
              <div className="col">
                <label>Wallet:</label>
                <div className="to-clipboard row row-space-between">
                  {wallet || "-"}
                </div>
              </div>
              <CopyOutlined />
            </div>
          </CopyToClipboard>
        </Form.Item>
      )}
      {children}
    </Modal>
  );
};

export default injectIntl(CollateralModal);
