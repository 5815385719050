import React from "react";
import { InputNumber } from "antd";

import "./CryptoInput.scss";

const CryptoInput = (props) => {
  const { maxCashout } = props;
  let maxNumber = maxCashout || Number.MAX_SAFE_INTEGER;

  return (
    <InputNumber
      defaultValue="0"
      min="0"
      max={maxNumber}
      step="0.0001"
      stringMode
      {...props}
    />
  );
};

export default CryptoInput;
