import { Button } from "antd";
import { DollarCircleOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { injectIntl } from "react-intl";

import "./CreditAvailable.scss";

const CreditAvailable = ({ enabledCreditButton }) => {
  return (
    <div>
      {enabledCreditButton ? (
        <div className="CreditAvailable row row-vertical-center">
          <Link to={`/solicitud-de-credito?new`}>
            <Button icon={<DollarCircleOutlined />} type="primary">
              Solicitar crédito
            </Button>
          </Link>
        </div>
      ) : null}
    </div>
  );
};

export default injectIntl(CreditAvailable);
