import { Form, Input, Button } from "antd";
import { gql, useMutation, useLazyQuery } from "@apollo/client";
import { injectIntl } from "react-intl";
import CollateralModal from "../../../components/CollateralModal";
import CryptoInput from "../../../components/CryptoInput";
import { useEffect, useState } from "react";

import "./CashoutModal.scss";

const TRANSACTION_ADD = gql`
  mutation CollateralTransactionAdd(
    $collateralTransactionToAdd: CollateralTransactionToAdd!
  ) {
    collateralTransactionAdd(
      collateralTransactionToAdd: $collateralTransactionToAdd
    ) {
      id
    }
  }
`;

const TRANSACTION_MAX = gql`
  query CollateralTransactionMaxCashout($crypto: CryptosAllowed!) {
    collateralTransactionMaxCashout(crypto: $crypto)
  }
`;

function CashoutModal(props) {
  const [form] = Form.useForm();

  const { setVisibility } = props;

  const [currency, setCurrency] = useState();

  const [maxCashout, setMaxCashOut] = useState();

  const [addTransaction, { loading }] = useMutation(TRANSACTION_ADD);

  const [
    collateralMaxCashout,
    { loading: collateralTransactionMaxCashoutLoading, data: maxCashoutData },
  ] = useLazyQuery(TRANSACTION_MAX, {
    onCompleted: () => {
      const values = form.getFieldsValue();

      form.setFieldsValue({
        ...values,
        amount: "0",
      });

      setMaxCashOut(maxCashoutData.collateralTransactionMaxCashout);
    },
  });

  useEffect(() => {
    if (form.getFieldsValue()?.currency) {
      collateralMaxCashout({
        variables: {
          crypto: form.getFieldsValue().currency,
        },
      });
    }
  }, [collateralMaxCashout, form, currency]);

  const putMaxCashout = () => {
    const values = form.getFieldsValue();
    if (maxCashoutData?.collateralTransactionMaxCashout !== values.amount) {
      form.setFieldsValue({
        ...values,
        amount: maxCashoutData?.collateralTransactionMaxCashout || 0,
      });
    }
  };

  const onFinish = () => {
    addTransaction({
      variables: {
        collateralTransactionToAdd: {
          ...form.getFieldsValue(),
          type: "CASHOUT",
        },
      },
    }).then(() => {
      form.resetFields();
      props.onSuccess();
    });
  };

  const onCancel = () => {
    form.resetFields();
    setVisibility(false);
  };

  const onCryptoChanged = ({ cryptoSelected }) => {
    form.setFieldsValue({
      ...form.getFieldsValue(),
      currency: cryptoSelected,
    });
    setCurrency(cryptoSelected);
  };

  return (
    <CollateralModal
      loading={loading}
      title="Retirar fondos"
      hideAmountRequired={true}
      hideWallet={true}
      onChange={onCryptoChanged}
      onCancel={onCancel}
      onOk={form.submit}
      {...props}
    >
      <Form
        onFinish={onFinish}
        className="CashoutModal"
        form={form}
        layout="vertical"
      >
        <Form.Item
          name="currency"
          hidden
          rules={[
            {
              required: true,
              message: "Por favor ingresa una moneda",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item className="row">
          <Form.Item
            name="amount"
            rules={[
              {
                required: true,
                message: "Por favor ingresa un monto",
              },
            ]}
          >
            <CryptoInput
              maxCashout={maxCashout || 0}
              placeholder="Monto a retirar"
            />
          </Form.Item>
          <Button
            disabled={!currency}
            loading={collateralTransactionMaxCashoutLoading}
            type="primary"
            onClick={putMaxCashout}
          >
            MAX
          </Button>
        </Form.Item>
        <Form.Item
          name="wallet"
          rules={[
            {
              required: true,
              message: "Por favor ingresa una wallet",
            },
          ]}
        >
          <Input placeholder="billetera" />
        </Form.Item>
      </Form>
    </CollateralModal>
  );
}

export default injectIntl(CashoutModal);
