import React, { useState, useEffect } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Form, Button, Input } from 'antd';
import { ReactComponent as Logo } from '../../assets/images/logo-black.svg';
import { gql, useLazyQuery } from '@apollo/client';
import {
  lPhantPrivacyPolicyLink,
  lPhantServiceTermsLink,
} from '../../utils/variables';
import Cookies from 'js-cookie';

import './Login.scss';

const LOGIN = gql`
  query Login($userToLogin: UserToLogin!) {
    login(userToLogin: $userToLogin) {
      id
    }
  }
`;

function Login(props) {
  const [userToLogin, setForm] = useState();

  const [loginQuery, { loading, data }] = useLazyQuery(LOGIN);

  const history = useHistory();
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  const amountParam = query.get('amount');
  const monthsParam = query.get('months');

  const onChange = (value) => {
    setForm({
      ...userToLogin,
      ...value,
    });
  };

  const onFinish = () => {
    loginQuery({
      variables: {
        userToLogin,
      },
    });
  };

  const navigate = () => {
    if (data) {
      if (!amountParam || !monthsParam) {
        history.push('/dashboard');
      } else {
        history.push(
          `/solicitud-de-credito/?amount=${amountParam}&months=${monthsParam}`
        );
      }
    }
  };

  useEffect(navigate, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (Cookies.get('is-logged') === 'true') {
      history.push('dashboard');
    }
  }, [history]); // eslint-disable-line react-hooks/exhaustive-deps

  const { intl } = props;

  return (
    <div className='AuthForm Login flex-row'>
      <div className='col background-image'></div>
      <div className='col background-image-text-1'>
        <h1>
          <FormattedMessage id='welcome-back-title'></FormattedMessage>
        </h1>
      </div>
      <div className='col background-image-text-2'>
        <a
          target='_blank'
          rel='noopener noreferrer external'
          href={lPhantServiceTermsLink}
          className='password-recovery-link'
        >
          <h4>
            <FormattedMessage id='credit-terms-tab'></FormattedMessage>
          </h4>
        </a>
        <a
          target='_blank'
          rel='noopener noreferrer external'
          href={lPhantPrivacyPolicyLink}
          className='password-recovery-link'
        >
          <h4>
            <FormattedMessage id='credit-policy-tab'></FormattedMessage>
          </h4>
        </a>
      </div>
      <div className='col col-vertical-center col-center flex-auth'>
        <Form
          layout='vertical'
          name='login'
          onValuesChange={onChange}
          onFinish={onFinish}
        >
          <div className='logo'>
            <Logo />
          </div>

          <h2>
            <FormattedMessage id='login-title'></FormattedMessage>
          </h2>
          <Form.Item
            label={intl.formatMessage({ id: 'user-label' })}
            name='email'
            rules={[
              {
                required: true,
                message: intl.formatMessage({ id: 'email-required-error' }),
              },
            ]}
          >
            <Input
              type='email'
              placeholder={intl.formatMessage({
                id: 'login-email-placeholder',
              })}
              size='large'
            />
          </Form.Item>
          <Form.Item
            label={intl.formatMessage({ id: 'password-label' })}
            name='password'
            rules={[
              {
                required: true,
                message: intl.formatMessage({ id: 'password-required-error' }),
              },
            ]}
          >
            <Input.Password
              placeholder={intl.formatMessage({
                id: 'login-password-placeholder',
              })}
              size='large'
            />
          </Form.Item>
          <Form.Item>
            <Link to='/recuperar-contrasena' className='password-recovery-link'>
              <FormattedMessage id='password-recovery-link'></FormattedMessage>
            </Link>
          </Form.Item>
          <Form.Item>
            <div className='buttons-container'>
              <Button
                loading={loading}
                type='primary'
                htmlType='submit'
                style={{ marginRight: '1vw' }}
              >
                {intl.formatMessage({ id: 'login-button' })}
              </Button>
              <Link
                to={
                  !!amountParam && !!monthsParam
                    ? `/registrarse/?amount=${amountParam}&months=${monthsParam}`
                    : '/registrarse'
                }
              >
                <Button
                  disabled={loading}
                  type='secondary'
                  ghost
                  style={{ marginLeft: '1vw' }}
                >
                  {intl.formatMessage({ id: 'sign-up-button' })}
                </Button>
              </Link>
            </div>
          </Form.Item>
        </Form>
        <div className='terms-tablet'>
        <a
          target='_blank'
          rel='noopener noreferrer external'
          href={lPhantServiceTermsLink}
          className='password-recovery-link'
        >
          <h4>
            <FormattedMessage id='credit-terms-tab'></FormattedMessage>
          </h4>
        </a>
        <a
          target='_blank'
          rel='noopener noreferrer external'
          href={lPhantPrivacyPolicyLink}
          className='password-recovery-link'
        >
          <h4>
            <FormattedMessage id='credit-policy-tab'></FormattedMessage>
          </h4>
        </a>
      </div>
      </div>
    </div>
  );
}

export default injectIntl(Login);
