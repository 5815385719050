import { useState, useEffect } from "react";
import { Layout as AntLayout, Menu, Avatar } from "antd";
import { ReactComponent as Imagotipo } from "../../assets/images/logo-white.svg";
import {
  HomeOutlined,
  LogoutOutlined,
  StockOutlined,
  WalletOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { injectIntl } from "react-intl";
import { gql, useLazyQuery, useQuery } from "@apollo/client";
import Spin from "../Spin";
import User from "../User";
import CreditAvailable from "../CreditAvailable";
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom";

import "./Layout.scss";
import { Link } from "react-router-dom";

const { Header, Content, Sider } = AntLayout;

const LOGOUT = gql`
  query Logout {
    logout
  }
`;

const USER = gql`
  query UserByToken {
    userByToken {
      id
      email
      bannerStatus
      rfc
      firstName
      lastName
      gender
      phone
      address
      zipCode
      bankAccountClabe
      idCard
      addressDocument
      incomeDocument
      bankStatement
      otherDocuments
    }
  }
`;

const Layout = ({ children, getUser, disabledCreditButton }) => {
  const { loading: userLoading, data: userData } = useQuery(USER, {
    refetchInterval: 1000,
    pollInterval: 60000,
    onCompleted: () => {
      if (userData.bannerStatus === "CREDITREQUESTPENDING")
        setEnabledCreditButton(true && !disabledCreditButton);
      if (getUser) getUser(userData.userByToken);
      setUser(userData.userByToken);
    },
  });

  const items = [
    {
      name: "Inicio",
      icon: <HomeOutlined />,
      path: "/dashboard",
    },
    {
      name: "Crédito",
      icon: <StockOutlined />,
      path: "/credito",
    },
    {
      name: "Cartera",
      icon: <WalletOutlined />,
      path: "/billetera",
    },
    {
      name: "Perfil",
      icon: <UserOutlined />,
      path: "/perfil",
    },
  ];

  const [user, setUser] = useState({});
  const [enabledCreditButton, setEnabledCreditButton] = useState(false);

  const [logoutQuery, { loading, data }] = useLazyQuery(LOGOUT);

  const history = useHistory();

  const logout = () => {
    if (Cookies.get("is-logged") === undefined) {
      history.push("/iniciar-sesion");
    }
    if (data?.logout) {
      window.location.reload();
    }
  };

  useEffect(logout, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  const initials =
    `${(user?.firstName || "")[0]}${(user?.lastName || "")[0]}` || null;

  const getSelectedKey = () => {
    return items
      .findIndex((item) => window.location.toString().indexOf(item.path) > -1)
      .toString();
  };

  if (loading) return <Spin />;

  return (
    <div className="Layout">
      <User loading={userLoading}></User>
      <AntLayout style={{ minHeight: "100vh" }}>
        <Header className="header">
          <div className="logo">
            <Imagotipo />
          </div>
          <CreditAvailable enabledCreditButton={enabledCreditButton} />
          <div>
            <Avatar>{initials}</Avatar>
            <LogoutOutlined onClick={logoutQuery} />
          </div>
        </Header>
        <AntLayout>
          <Sider collapsed={true}>
            <Menu
              checkedChildren="Dark"
              selectedKeys={[getSelectedKey()]}
              mode="inline"
            >
              {items.map((item, index) => (
                <Menu.Item key={index} icon={item.icon}>
                  <Link to={item.path}>{item.name}</Link>
                </Menu.Item>
              ))}
            </Menu>
          </Sider>
          <Content>
            <div className="content-wrapper">{children}</div>
          </Content>
        </AntLayout>
      </AntLayout>
    </div>
  );
};

export default injectIntl(Layout);
