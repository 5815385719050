import React, { useState } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { useParams, useHistory, Link } from 'react-router-dom';
import { Form, Input, Button } from 'antd';
import { ReactComponent as Logo } from '../../assets/images/logo-black.svg';
import { gql, useMutation } from '@apollo/client';
import { passwordPattern } from '../../utils/patterns';

import Spin from '../../components/Spin';

import './UpdatePassword.scss';

const PASSWORD_RECOVERY = gql`
  mutation PasswordRecovery($password: String!, $token: String!) {
    passwordRecovery(password: $password, token: $token) {
      id
    }
  }
`;

function UpdatePassword(props) {
  const { token } = useParams();

  const history = useHistory();

  const [form, setForm] = useState({});

  const onChange = (value) => {
    setForm({
      ...form,
      ...value,
    });
  };

  const [passwoordRecoveryMutation, { loading }] =
    useMutation(PASSWORD_RECOVERY);

  const onFinish = () => {
    passwoordRecoveryMutation({
      variables: {
        token,
        password: form.password,
      },
    }).then(() => history.push('/solicitud-de-credito'));
  };

  const { intl } = props;

  if (loading) return <Spin />;

  return (
    <div className='AuthForm flex-row'>
      <div className='col col-vertical-center col-center'>
        <Form
          layout='vertical'
          name='passwordRecovery'
          onValuesChange={onChange}
          onFinish={onFinish}
        >
          <div className='logo'>
            <Logo />
          </div>
          <h1>
            <FormattedMessage id='update-password-title'></FormattedMessage>
          </h1>
          <Form.Item
            label={intl.formatMessage({ id: 'password-label' })}
            name='password'
            hasFeedback
            rules={[
              {
                required: true,
                pattern: passwordPattern,
                message: intl.formatMessage({ id: 'invalid-password-error' }),
              },
              {
                min: 8,
                required: true,
                message: intl.formatMessage({ id: 'password-required-error' }),
              },
            ]}
          >
            <Input.Password
              placeholder={intl.formatMessage({ id: 'password-placeholder' })}
              size='large'
            />
          </Form.Item>
          <Form.Item
            label={intl.formatMessage({ id: 'repeat-password-label' })}
            name='passwordConfirmation'
            dependencies={['password']}
            hasFeedback
            rules={[
              {
                required: true,
                pattern: passwordPattern,
                message: intl.formatMessage({ id: 'invalid-password-error' }),
              },
              {
                min: 8,
                required: true,
                message: intl.formatMessage({ id: 'password-required-error' }),
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    intl.formatMessage({ id: 'passwords-do-not-match' })
                  );
                },
              }),
            ]}
          >
            <Input.Password
              placeholder={intl.formatMessage({ id: 'password-placeholder' })}
              size='large'
            />
          </Form.Item>
          <Form.Item>
            <Button loading={loading} type='primary' htmlType='submit'>
              {intl.formatMessage({ id: 'continue-button' })}
            </Button>
          </Form.Item>
          <p>
            <Link to='/iniciar-sesion'>
              <FormattedMessage id='login-back'></FormattedMessage>
            </Link>
          </p>
        </Form>
      </div>
      <div className='col background-image'></div>
    </div>
  );
}

export default injectIntl(UpdatePassword);
