const messages = {
  appName: "L-Phant",

  male: "Masculino",
  female: "Femenino",

  "login-title": "Bienvenido a L-Phant",
  "welcome-back-title": "¡Bienvenido de vuelta!",

  "contact-form-title": "Suscríbete a nuestro Newsletter",
  "contact-form-email-label": "Correo electrónico",
  "contact-form-email-placeholder": "Correo electrónico",
  "contact-form-name-label": "Nombre",
  "contact-form-name-placeholder": "Nombre",
  "contact-form-submit-button": "Suscribirse",
  "contact-form-no-thanks-button": "No, gracias",
  "contact-form-success-message": "¡Gracias por suscribirte!. Ya puedes calcular tu crédito",

  "email-label": "Email",
  "user-label": "Usuario",
  "login-email-placeholder": "Correo electrónico",
  "sign-up-email-placeholder": "Ingresa tu email",
  "email-required-error": "Por favor ingresa un email válido",

  "firstName-label": "Nombre",
  "firstName-placeholder": "Ingresa tu nombre",
  "firstName-required-error": "Por favor ingresa tu nombre",

  "lastName-label": "Apellido",
  "lastName-placeholder": "Ingresa tu apellido",
  "lastName-required-error": "Por favor ingresa tu apellido",

  "address-label": "Dirección",
  "address-placeholder": "Ingresa tu dirección",
  "address-required-error": "Por favor ingresa tu dirección",

  "zipCode-label": "Código postal",
  "zipCode-placeholder": "Ingresa tu código postal",
  "zipCode-required-error": "Por favor ingresa tu código postal",

  "rfc-label": "RFC",
  "rfc-placeholder": "Ingresa tu RFC",
  "rfc-required-error": "Por favor ingresa tu RFC",

  "gender-label": "Género",
  "gender-placeholder": "Ingresa tu género",
  "gender-required-error": "Por favor ingresa tu género",
  "gender-unknown": "No responder",

  "phone-label": "Teléfono",
  "phone-placeholder": "Ingresa tu teléfono",
  "phone-required-error": "Por favor ingresa tu teléfono",

  "bankAccountClabe-label": "CLABE",
  "bankAccountClabe-placeholder": "Ingresa tu CLABE",
  "bankAccountClabe-required-error": "Por favor ingresa tu CLABE",

  "password-label": "Contraseña",
  "login-password-placeholder": "Escribe tu contraseña",
  "sign-up-password-placeholder": "Ingresa tu contraseña",
  "invalid-password-error":
    "La contraseña debe contener al menos una mayúscula, una minúscula y un número.",
  "password-required-error": "Ingresa una contraseña válida",

  "login-button": "INICIA SESIÓN",
  "sign-up-button": "CREAR CUENTA",

  "create-account-button": "Crear cuenta",
  "continue-button": "Continuar",

  "sign-up-label": "¿Eres nuevo?",
  "sign-up-link": "Crea una cuenta",

  "login-label": "¿Ya estás registrado?",
  "login-link": "Inicia sesión",

  "password-recovery-title": "¿Olvidaste tu contraseña?",
  "password-recovery-link": "¿Olvidaste tu contraseña?",
  "login-back": "Volver",

  "update-password-title": "Ingresa tu nueva contraseña",
  "repeat-password-label": "Repite tu contraseña",

  "passwords-do-not-match": "Las contraseñas no coinciden",

  "sign-up-title": "Crea una cuenta en L-Phant",
  "create-account-title": "Crea tu cuenta L-phant",
  "sign-up-terms-required-1": "He leido y acepto los ",
  "sign-up-terms-required-2": ", política de privacidad de L-phant.",
  "sign-up-link-terms-required": " términos y condiciones ",
  "sign-up-link-privacy-policy-required": " política de privacidad ",
  and: "y",

  "credit-terms-tab": "Términos y condiciones",
  "credit-policy-tab": "Política de privacidad",

  "credit-request-title": "Solicita ahora tu ",
  "credit-request-title-highligh": "CriptoCrédito",

  "credit-requested-title": "Has solicitado tu ",
  "credit-requested-title-highligh": "CriptoCrédito",
  "credit-requested-message":
    "Recibirás un correo de confirmación para continuar con el proceso.",

  "credit-calculator-tab": "Modifica tu crédito",
  "credit-resume-tab": "Resumen",
  "credit-calculator-title": "Calcula tu Cripto Crédito L-phant",
  "credit-calculator-title-credit":
    "Obtén tu cripto crédito en menos de 24 hrs, sin papeleo, sin revisión de historial crediticio, solo deja tus activos digitales  en garantía.",

  "credit-calculator-amount-label": "¿Cuánto quieres pedir prestado?*",
  "credit-calculator-amount-label-2": "Monto del crédito:",
  "credit-calculator-amount-placeholder": "Escribe la cantidad…",
  "credit-calculator-amount-limits":
    "Desde minCreditAmount hasta maxCreditAmount",
  "credit-calculator-months-label":
    "¿En cuánto tiempo quieres pagar tu Cripto Crédito L-Phant?*",
  "credit-next-button": "CONTINUAR",

  "credit-result-title": "Pago mensual",
  "credit-result-amount": "Monto del crédito",
  "credit-result-cat": "CAT %",
  "credit-result-credit-cost": "Costo total del crédito",
  "credit-result-credit-total": "Monto total a pagar",
  "credit-result-tax": "Intereses",
  "credit-result-collaterals": "Cripto en garantía requerido",
  "credit-result-currency": "Pesos Mexicanos en:",
  "credit-result-opening": "Comisión por apertura",

  "credit-card-number-label": "Cuenta bancaria",
  "credit-card-number-placeholder": "Ingresa tu número de tarjeta",
  "credit-card-number-required-error": "Ingresa una tarjeta válida",

  "credit-card-clabe-label": "Clabe (16 digitos)",
  "credit-card-clabe-placeholder": "Ingresa tu clabe",
  "credit-card-clabe-required-error": "Por favor ingresa una Clabe válida",

  "personal-credit-card-title": "Créditos Personales",
  "personal-credit-card-content":
    "Solicita un crédito personal dejando tus activos digitales como garantía.",
  "personal-credit-card-button": "SOLICITA TU CRÉDITO",

  "credit-details-card-title": "Detalles de tu crédito",
  "credit-details-card-content": "Revisa tu crédito personal",
  "credit-details-card-button": "IR A MI CREDITO",

  "wallet-card-title": "L-Phant Wallet",
  "wallet-card-content":
    "Resguarda de manera segura tus activos digitales con nosotros.",
  "wallet-card-button": "DEPOSITAR A MI WALLET",

  "products-card-title": "Productos Financieros",
  "products-card-content":
    "Conoce más de L-Phant, tu plataforma de productos financieros basados en activos digitales.",
  "products-card-button": "SABER MÁS",

  "plural-month": "Meses",
  "singular-month": "Mes",

  "terms-message":
    "Al dar clic al botón “Acepto” declaro que he leído y he aceptado  los términos y condiciones de Carninal.",
  "terms-accept-button": "Acepto los términos y condiciones",

  "userStatus.EMAILVERIFICATIONPENDING": "Correo NO verificado",
  "userStatusContent.EMAILVERIFICATIONPENDING":
    "Es necesario verificar tu correo electrónico para continuar con tu solicitud de crédito.",
  "userStatusColor.EMAILVERIFICATIONPENDING": "client",
  "userStatusButtonText.EMAILVERIFICATIONPENDING": "RE-ENVIAR CORREO",

  "userStatus.CREDITREQUESTPENDING": "Aún no cuentas con un crédito ",
  "userStatusContent.CREDITREQUESTPENDING":
    "Vamos a solucionarlo, solicita tu Cripto Crédito L-Phant…",
  "userStatusColor.CREDITREQUESTPENDING": "client",
  "userStatusButtonText.CREDITREQUESTPENDING": "SOLICITAR UN CRÉDITO",

  "userStatus.PERSONALINFOPENDING": "Nos faltan algunos datos",
  "userStatusContent.PERSONALINFOPENDING":
    "Tu solicitud está en progreso, solo falta completar tu información personal en tu perfil.",
  "userStatusColor.PERSONALINFOPENDING": "client",
  "userStatusButtonText.PERSONALINFOPENDING": "COMPLETAR PERFIL",

  "userStatus.KYCPENDING": "Estamos verificando tu información ",
  "userStatusContent.KYCPENDING":
    "Tu solicitud está en progreso. Nuestro equipo está verificando la información y se pondrán en contacto contigo para cualquier aclaración.",
  "userStatusColor.KYCPENDING": "lphant",
  "userStatusButtonText.KYCPENDING": "",

  "userStatus.COLLATERALPENDING": "¡Estás a un paso de recibir tu crédito!",
  "userStatusContent.COLLATERALPENDING":
    "Deposita tus activos digitales en garantía.",
  "userStatusColor.COLLATERALPENDING": "client",
  "userStatusButtonText.COLLATERALPENDING": "DEPOSITAR",

  "userStatus.PAYMENTPENDING": "Tienes un pago atrasado en tu crédito",
  "userStatusContent.PAYMENTPENDING": "Haz el pago correspondiente",
  "userStatusColor.PAYMENTPENDING": "client",
  "userStatusButtonText.PAYMENTPENDING": "REPORTAR PAGO",

  "userStatus.CREDITAPPROVED": "¡Crédito otorgado!",
  "userStatusContent.CREDITAPPROVED":
    "El depósito de tu crédito está en proceso, gracias por tu paciencia.",
  "userStatusColor.CREDITAPPROVED": "lphant",
  "userStatusButtonText.CREDITAPPROVED": "",

  "creditStatus.EMAILVERIFICATIONPENDING": "Correo NO verificado",
  "creditStatusContent.EMAILVERIFICATIONPENDING":
    "Es necesario verificar tu correo electrónico para continuar tu solicitud de crédito",
  "creditStatusColor.EMAILVERIFICATIONPENDING": "client",

  "creditStatus.KYCPENDING": "KYC pendiente",
  "creditStatusContent.KYCPENDING": "KYC pendiente",
  "creditStatusColor.KYCPENDING": "client",

  "creditStatus.COLLATERALPENDING": "Colateral pendiente",
  "creditStatusContent.COLLATERALPENDING": "Colateral pendiente",
  "creditStatusColor.COLLATERALPENDING": "client",

  "creditStatus.APPROVED": "Aprobado",
  "creditStatusContent.APPROVED": "Aprobado",
  "creditStatusColor.APPROVED": "lphant",

  "creditStatus.DECLINED": "Declinado",
  "creditStatusContent.DECLINED": "Declinado",
  "creditStatusColor.DECLINED": "lphant",

  "creditStatus.STARTED": "Iniciado",
  "creditStatusContent.STARTED": "Iniciado",
  "creditStatusColor.STARTED": "lphant",

  "creditStatus.PAYMENTPENDING": "Pago pendiente",
  "creditStatusContent.PAYMENTPENDING": "Pago pendiente por pagarse",
  "creditStatusColor.PAYMENTPENDING": "client",

  "creditStatus.FINISHEDPAID": "Pago completado",
  "creditStatusContent.FINISHEDPAID": "Pago completado",
  "creditStatusColor.FINISHEDPAID": "client",

  "creditStatus.FINISHEDUNPAID": "Pago incompleto",
  "creditStatusContent.FINISHEDUNPAID": "Pago incompleto",
  "creditStatusColor.FINISHEDUNPAID": "client",
  "collateral-deposit-success": "Se ha depositado la garantía de forma exitosa",
  "collateral-cashout-success": "Se ha retirado la garantía de forma exitosa",

  balance: "Monto para liquidar",
  advance: "Avance del Crédito",
  status: "Status",
  "due-at": "Vencido al",
  "to-pay-at": "A pagar el",

  "your-information-label-1": "Tu información:",
  "your-information-label-2": "Documentación complementaria:",

  "wallet-copied-to-clipboard": "La wallet se ha copiado al portapapeles",
  "amount-required": "Monto sugerido a depositar",
  "unused-amount": "Monto disponible",
  "used-amount": "Monto utilizado",
  "lphant-copyright": "©L-Phant 2020, Todos los derechos reservados",
  "collateral-coin-confirm":
    "Envía coin a la dirección indicada, tu pago será procesado en aproximadamente 20 minutos. Asegúrate de copiar correctamente la dirección y de enviar sólo coin, ya que si envías una criptomoneda diferente no podremos progresar tu pago",
};
export default messages;
