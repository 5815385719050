import { Button, Card } from "antd";
import {
  DollarCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { injectIntl } from "react-intl";

import "./RequestNewCredit.scss";

function RequestNewCredit(props) {
  return (
    <div className="RequestNewCredit">
      <Card>
        <ExclamationCircleOutlined />
        <p>
          <b>¡Vaya!</b>
          <br />
          No tienes solicitudes de crédito activas. Presiona el botón para crear
          una.
        </p>
        <Link to={`/solicitud-de-credito?new`}>
          <Button size="large" icon={<DollarCircleOutlined />} type="primary">
            Solicitar credito
          </Button>
        </Link>
      </Card>
    </div>
  );
}

export default injectIntl(RequestNewCredit);
