import "../../theme/colors.scss";
import React, { useState, useEffect } from "react";
import { injectIntl } from "react-intl";
import { Form, Button, Card, Slider, Checkbox } from "antd";
import CurrencyInput from "../CurrencyInput";
import { gql, useQuery } from "@apollo/client";
import getCryptoIcon from "../../constants/crypto-icons";
import { lPhantServiceTermsLink } from "../../utils/variables";

import "./Calculator.scss";
import { calculateCat } from "../../helpers";

const COLLATERAL = gql`
  query CollateralToken {
    collateralByToken {
      balances {
        BTC
        ETH
      }
    }
  }
`;

// {toMoneyFormat(credit?.amount + credit?.opening * 1.16)}

const Calculator = ({
  intl,
  formProps,
  loanAmount,
  loanMonths,
  product,
  tooltipVisible,
}) => {
  const [acceptedTerms, hasAcceptedTerms] = useState(false);

  const { data } = useQuery(COLLATERAL);

  const { collateralByToken } = data || {};

  const minCreditAmount = product.minAmount;

  const maxCreditAmount = product.maxAmount;

  const [credit, setCredit] = useState();

  const [formValue, setFormValue] = useState({});

  const roundNumber = (number) =>
    number
      ? `${(parseFloat(number) || 0)
          .toFixed(2)
          .replace(/\d(?=(\d{3})+\.)/g, "$&,")}`
      : "-";

  const toMoneyFormat = (number) => (number ? `$ ${roundNumber(number)}` : "-");

  useEffect(
    (_) => {
      setFormValue({
        amount: loanAmount,
        months: loanMonths,
      });
    },
    [loanAmount, loanMonths]
  );

  useEffect(() => {
    const amount = Number(formValue.amount);
    const months = formValue.months;
    if (
      amount &&
      amount >= minCreditAmount &&
      amount <= maxCreditAmount &&
      months
    ) {
      const monthlyRate = product.interestRate * 1.16;

      const opening = amount * product.openingFee * 1.16;
      const monthlyPayment =
        ((amount + opening) * ((1 + monthlyRate) ** months * monthlyRate)) /
        ((1 + monthlyRate) ** months - 1);
      const total = monthlyPayment * months;
      const cat = calculateCat({ amount, monthlyPayment, totalPayments: months });
      const collaterals = amount * product.collateralRequired;

      setCredit({
        monthlyPayment,
        amount,
        cat,
        tax: 1,
        months,
        collaterals,
        total,
        opening,
      });
    } else {
      setCredit({});
    }
  }, [formValue, maxCreditAmount, minCreditAmount, product]); // eslint-disable-line react-hooks/exhaustive-deps

  const onChange = (value) => {
    setFormValue({
      ...formValue,
      ...value,
    });
  };

  return (
    <div className="col">
      <div className="calculator">
        <Form
          layout="vertical"
          name="credit"
          className="col"
          onValuesChange={onChange}
          initialValues={formValue}
          {...formProps}
        >
          <div className="row">
            {!!formProps && (
              <div className="col">
                <Form.Item
                  label={
                    <div className="col">
                      {intl.formatMessage({
                        id: "credit-calculator-amount-label",
                      })}
                      <label className="label-calculator">
                        <h3>
                          {intl
                            .formatMessage({
                              id: "credit-calculator-amount-limits",
                            })
                            .replace(
                              "minCreditAmount",
                              toMoneyFormat(minCreditAmount)
                            )
                            .replace(
                              "maxCreditAmount",
                              toMoneyFormat(maxCreditAmount)
                            )}
                        </h3>
                      </label>
                    </div>
                  }
                  initialValue={loanAmount}
                  name="amount"
                  rules={[
                    { required: true, message: "Please input your amount!" },
                  ]}
                >
                  <CurrencyInput
                    min={minCreditAmount}
                    max={maxCreditAmount}
                    size="large"
                    placeholder={intl.formatMessage({
                      id: "credit-calculator-amount-placeholder",
                    })}
                  />
                </Form.Item>
                <Form.Item
                  label={intl.formatMessage({
                    id: "credit-calculator-months-label",
                  })}
                  name="months"
                  rules={[
                    {
                      required: true,
                      message: "Please input your desired months!",
                    },
                  ]}
                  className="slider-container"
                  initialValue={loanMonths}
                >
                  <Slider
                    step={1}
                    min={product.minPayments}
                    max={product.maxPayments}
                    tooltipVisible={tooltipVisible}
                    tooltipPlacement="top"
                    tipFormatter={(tipFormatter) => (
                      <div className="tooltip">{tipFormatter + " MESES"}</div>
                    )}
                    trackStyle={{
                      backgroundColor: "var (--primary-color)",
                      color: "#FF0000",
                    }}
                  >
                    <div className="slider-span">
                      <span className="min-payments">
                        {product.minPayments} meses
                      </span>
                      <span className="max-payments">
                        {product.maxPayments} meses
                      </span>
                    </div>
                  </Slider>
                </Form.Item>
                <div className="display-pc">
                  {!!formProps && (
                    <Form.Item>
                      <Checkbox
                        onClick={(_) => hasAcceptedTerms(!acceptedTerms)}
                      >
                        Acepto los
                        <a
                          target="_blank"
                          rel="noopener noreferrer external"
                          href={lPhantServiceTermsLink}
                        >
                          {intl.formatMessage({
                            id: "sign-up-link-terms-required",
                          })}
                        </a>
                      </Checkbox>
                    </Form.Item>
                  )}
                  {!!formProps && (
                    <Form.Item>
                      <Button
                        disabled={!acceptedTerms}
                        type="primary"
                        htmlType="submit"
                      >
                        {intl.formatMessage({ id: "credit-next-button" })}
                      </Button>
                    </Form.Item>
                  )}
                </div>
              </div>
            )}
          </div>
        </Form>
        <div className="col">
          <Card bordered={false}>
            <div className="col">
              <span className="big-text">
                {intl.formatMessage({ id: "credit-result-title" })}{" "}
                {credit?.months
                  ? `(${credit.months} ${intl.formatMessage({
                      id: credit.months > 1 ? "plural-month" : "singular-month",
                    })})`
                  : ""}
              </span>
              <span className="big-text-bold">
                {toMoneyFormat(credit?.monthlyPayment)}
              </span>
            </div>
            <div className="row">
              <div className="col">
                <span className="small-text">
                  {intl.formatMessage({ id: "credit-result-amount" })}
                </span>
                <span className="small-text-bold">
                  {toMoneyFormat(credit?.amount)}
                </span>
              </div>
              <div className="col">
                <span className="small-text">
                  {intl.formatMessage({ id: "credit-result-opening" })}
                </span>
                <span className="small-text-bold">
                  {toMoneyFormat(credit?.opening)}
                </span>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <span className="small-text">
                  {intl.formatMessage({ id: "credit-result-credit-cost" })}
                </span>
                <span className="small-text-bold">
                  {toMoneyFormat(credit?.total - credit?.amount)}
                </span>
              </div>
              <div className="col">
                <span className="small-text">
                  {intl.formatMessage({ id: "credit-result-cat" })}
                </span>
                <span className="small-text-bold">
                  {roundNumber(credit?.cat)}
                </span>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <span className="small-text">
                  {intl.formatMessage({ id: "credit-result-credit-total" })}
                </span>
                <span className="small-text-bold">
                  {toMoneyFormat(credit?.total)}
                </span>
              </div>
            </div>
            <div className="row space-top">
              <div className="col">
                <span className="small-text">
                  {intl.formatMessage({ id: "credit-result-collaterals" })}
                </span>
                <span className="small-text-bold">
                  {credit?.collaterals
                    ? `${toMoneyFormat(
                        credit?.collaterals
                      )} ${intl.formatMessage({
                        id: "credit-result-currency",
                      })}`
                    : "-"}

                  {credit?.collaterals && (
                    <div className="row coins">
                      {Object.keys(collateralByToken?.balances || {})
                        .filter((key) => key !== "__typename")
                        .map((crypto, index) => (
                          <div key={index}>{getCryptoIcon(crypto)}</div>
                        ))}
                    </div>
                  )}
                </span>
              </div>
            </div>
          </Card>
        </div>
      </div>
      <div className="display-tablet">
        {!!formProps && (
          <Form.Item>
            <Checkbox onClick={(_) => hasAcceptedTerms(!acceptedTerms)}>
              Acepto los
              <a
                target="_blank"
                rel="noopener noreferrer external"
                href={lPhantServiceTermsLink}
              >
                {intl.formatMessage({
                  id: "sign-up-link-terms-required",
                })}
              </a>
            </Checkbox>
          </Form.Item>
        )}
        {!!formProps && (
          <Form.Item>
            <Button disabled={!acceptedTerms} type="primary" htmlType="submit">
              {intl.formatMessage({ id: "credit-next-button" })}
            </Button>
          </Form.Item>
        )}
      </div>
    </div>
  );
};

export default injectIntl(Calculator);
