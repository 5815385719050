import React from "react";
import { injectIntl } from "react-intl";
import Header from "../../components/Header";
import { useHistory } from "react-router-dom";
import { Card, Button } from "antd";
import { CheckCircleTwoTone } from "@ant-design/icons";

import "./CreditRequested.scss";

function CreditRequested(props) {
  const { intl } = props;

  const history = useHistory();

  const onContinue = (_) => {
    history.push("/dashboard");
    window.location.reload();
  };

  return (
    <div className="CreditRequested col">
      <Header />
      <div className="content-wrapper vertical-space col">
        <Card bordered={false}>
          <div className="col">
            <CheckCircleTwoTone twoToneColor="#52c41a" />
            <label>
              {intl.formatMessage({ id: "credit-requested-message" })}
            </label>
            <Button type="primary" onClick={onContinue}>
              Continuar
            </Button>
          </div>
        </Card>
      </div>
    </div>
  );
}

export default injectIntl(CreditRequested);
