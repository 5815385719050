import { Table } from "antd";
import moment from "moment";
import { currencyFormat } from "../../../utils/format";
import "./PaymentReports.scss";

const status = {
  PENDING: "PENDIENTE",
  CONFIRMED: "CONFIRMADO",
  DECLINED: "RECHAZADO",
  REVERTED: "REVERTIDO",
};

function PaymentReports(props) {
  const columns = [
    {
      title: "Fecha",
      key: "createdAt",
      render: (_, record) => moment(record.createdAt).format("DD/MM/YYYY"),
    },
    {
      title: "Capital",
      key: "principalDelta",
      render: (_, record) => currencyFormat(record.principalDelta),
    },
    {
      title: "Intereses",
      key: "interestDelta",
      render: (_, record) => currencyFormat(record.interestDelta),
    },
    {
      title: "IVA de interés",
      key: "ivaDelta",
      render: (_, record) => currencyFormat(record.ivaDelta),
    },
    {
      title: "Recargo",
      key: "rechargeDelta",
      render: (_, record) => currencyFormat(record.rechargeDelta),
    },
    {
      title: "IVA de recargo",
      key: "rechargeIvaDelta",
      render: (_, record) => currencyFormat(record.rechargeIvaDelta),
    },
    {
      title: "Monto del pago",
      key: "paymentAMount",
      render: (_, record) => status[record.amount],
    },
    {
      title: "Estatus",
      dataIndex: "status",
      key: "status",
      render: (_, record) => status[record.status],
    },
  ];

  return (
    <div className="PaymentReports">
      <Table
        rowKey={"id"}
        pagination={false}
        columns={columns}
        title={() => "Historial de pagos"}
        dataSource={props.data}
      />
    </div>
  );
}

export default PaymentReports;
