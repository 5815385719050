import Layout from "../../components/Layout";
import { injectIntl } from "react-intl";
import { useState } from "react";
import { Col } from "antd";
import User from "../../components/User";
import "./Profile.scss";

function Profile() {
  const [creditSelected, setCreditSelected] = useState({});
  const [user, setUser] = useState();

  return (
    <div className="Profile">
      <Layout getUser={setUser}>
        <Col span={24}>
          <User
            user={user}
            credit={creditSelected}
            get={setCreditSelected}
          ></User>
        </Col>
      </Layout>
    </div>
  );
}

export default injectIntl(Profile);
