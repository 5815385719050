import React, { useEffect } from "react";
import Spin from "../../components/Spin";
import { gql, useQuery } from "@apollo/client";

const CREDITS = gql`
  query CreditsByToken(
    $isQueued: Boolean
    $isPending: Boolean
    $isOpen: Boolean
    $status: CreditStatus
    $page: Int
    $pageSize: Int
  ) {
    creditsByToken(
      isQueued: $isQueued
      isPending: $isPending
      isOpen: $isOpen
      status: $status
      page: $page
      pageSize: $pageSize
    ) {
      results {
        status
      }
    }
  }
`;

const Credits = ({ canRequestCredit }) => {
  const { loading, data } = useQuery(CREDITS);

  useEffect(() => {
    if (data?.creditsByToken) {
      let { results: credits } = data.creditsByToken;

      credits = credits.filter(
        (credit) =>
          credit.status.indexOf("FINISHEDPAID") === -1 &&
          credit.status.indexOf("DECLINED") === -1 &&
          credit.status.indexOf("FINISHEDUNPAID") === -1
      );

      canRequestCredit && canRequestCredit(!!credits.length);
    }
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  return loading ? <Spin /> : <div />;
};

export default Credits;
