const lPhantEmail = "contacto@lphant.com.mx";
const lPhantTelephone = "81-12-53-15-00 Ext. 114 ";
const lPhantEmailDisplay = "contacto@lphant.com.mx ";
const lPhantURL = "http://www.lphant.io";
const lPhantURLDisplay = "www.lphant.io ";
const lPhantSignUpURL = "http://app.lphant.io/crear-cuenta";
const lPhantSignUpURLDisplay = "http://app.lphant.io/crear-cuenta ";
const lPhantSignInURL = "http://app.lphant.io/iniciar-sesion";
const lPhantSignInURLDisplay = "http://app.lphant.io/iniciar-sesion ";
const lPhantFacebookURL = "https://www.facebook.com";
const lPhantInstagramURL = "https://www.instagram.com";
const lPhantTwitterURL = "https://twitter.com";
const lPhantPrivacyPolicyLink = "http://www.lphant.io/politica-de-privacidad";
const lPhantPrivacyPolicyLinkDisplay = "Política de Privacidad ";
const lPhantPasswordRecoveryURL = "http://app.lphant.io/recuperar-contrasena";
const lPhantPasswordRecoveryURLDisplay =
  "http://app.lphant.io/recuperar-contrasena ";
const lPhantCapMarketURL = "https://www.nomics.com";
const lPhantCapMarketURLDisplay = "www.nomics.com ";
const lPhantServiceTermsLinkDisplay =
  "http://www.lphant.io/terminos-y-condiciones";
const lPhantServiceTermsLink = "http://www.lphant.io/terminos-y-condiciones";
const lPhantPrivacyPolicy = "http://www.lphant.io/politica-de-privacidad";
const lPhantFAQSLinkDisplay = "http://www.lphant.io/faqs";

export {
  lPhantEmail,
  lPhantTelephone,
  lPhantEmailDisplay,
  lPhantURL,
  lPhantURLDisplay,
  lPhantSignUpURL,
  lPhantSignUpURLDisplay,
  lPhantSignInURL,
  lPhantSignInURLDisplay,
  lPhantFacebookURL,
  lPhantInstagramURL,
  lPhantTwitterURL,
  lPhantPrivacyPolicyLink,
  lPhantPrivacyPolicyLinkDisplay,
  lPhantPasswordRecoveryURL,
  lPhantPasswordRecoveryURLDisplay,
  lPhantCapMarketURL,
  lPhantCapMarketURLDisplay,
  lPhantPrivacyPolicy,
  lPhantServiceTermsLink,
  lPhantServiceTermsLinkDisplay,
  lPhantFAQSLinkDisplay,
};
