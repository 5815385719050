import React, { useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Form, Button, Input, notification } from 'antd';
import { ReactComponent as Logo } from '../../assets/images/logo-black.svg';
import { gql, useMutation } from '@apollo/client';
import {
  lPhantPrivacyPolicyLink,
  lPhantServiceTermsLink,
} from '../../utils/variables';

import './ContactForm.scss';

const ADD_CONTACT = gql`
  mutation contactAdd($name: String, $email: String) {
    contactAdd(name: $name, email: $email) {
      id
    }
  }
`;

function ContactForm(props) {
  const { intl } = props;

  const [form, setForm] = useState();

  const [contactAddMutation, { loading }] = useMutation(ADD_CONTACT);

  const onChange = (value) => {
    setForm({
      ...form,
      ...value,
    });
  };

  const onFinish = async () => {
    await contactAddMutation({
      variables: {
        ...form,
      },
    });

    notification.success({ message: intl.formatMessage({ id: 'contact-form-success-message' }) });

    setTimeout(() => window.location.href = 'https://www.lphant.io/es/calculadora/', 1500);
  };

  return (
    <div className='AuthForm Login flex-row'>
      <div className='col background-image'></div>
      <div className='col background-image-text-1'>
        <h1>
          <FormattedMessage id='login-title'></FormattedMessage>
        </h1>
      </div>
      <div className='col background-image-text-2'>
        <a
          target='_blank'
          rel='noopener noreferrer external'
          href={lPhantServiceTermsLink}
          className='password-recovery-link'
        >
          <h4>
            <FormattedMessage id='credit-terms-tab'></FormattedMessage>
          </h4>
        </a>
        <a
          target='_blank'
          rel='noopener noreferrer external'
          href={lPhantPrivacyPolicyLink}
          className='password-recovery-link'
        >
          <h4>
            <FormattedMessage id='credit-policy-tab'></FormattedMessage>
          </h4>
        </a>
      </div>
      <div className='col col-vertical-center col-center flex-auth'>
        <Form
          layout='vertical'
          name='login'
          onValuesChange={onChange}
          onFinish={onFinish}
        >
          <div className='logo'>
            <Logo />
          </div>

          <h2>
            <FormattedMessage id='contact-form-title'></FormattedMessage>
          </h2>
          <Form.Item
            label={intl.formatMessage({ id: 'contact-form-name-label' })}
            name='name'
          >
            <Input
              type='text'
              placeholder={intl.formatMessage({
                id: 'contact-form-name-placeholder',
              })}
              size='large'
            />
          </Form.Item>
          <Form.Item
            label={intl.formatMessage({ id: 'contact-form-email-label' })}
            name='email'
            rules={[
              {
                required: true,
                message: intl.formatMessage({ id: 'email-required-error' }),
              },
            ]}
          >
            <Input
              type='email'
              placeholder={intl.formatMessage({
                id: 'contact-form-email-placeholder',
              })}
              size='large'
            />
          </Form.Item>
          <Form.Item>
            <a href="https://www.lphant.io/calculadora/">
              <FormattedMessage id='contact-form-no-thanks-button'></FormattedMessage>
            </a>
          </Form.Item>
          <Form.Item>
            <div className='buttons-container'>
              <Button
                loading={loading}
                type='primary'
                htmlType='submit'
                style={{ marginRight: '1vw' }}
              >
                {intl.formatMessage({ id: 'contact-form-submit-button' })}
              </Button>
            </div>
          </Form.Item>
        </Form>
        <div className='terms-tablet'>
        <a
          target='_blank'
          rel='noopener noreferrer external'
          href={lPhantServiceTermsLink}
          className='password-recovery-link'
        >
          <h4>
            <FormattedMessage id='credit-terms-tab'></FormattedMessage>
          </h4>
        </a>
        <a
          target='_blank'
          rel='noopener noreferrer external'
          href={lPhantPrivacyPolicyLink}
          className='password-recovery-link'
        >
          <h4>
            <FormattedMessage id='credit-policy-tab'></FormattedMessage>
          </h4>
        </a>
      </div>
      </div>
    </div>
  );
}

export default injectIntl(ContactForm);
