import React, { useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Form, Button, Input, Checkbox, Image, Modal } from 'antd';
import { ReactComponent as Logo } from '../../assets/images/logo-black.svg';
import { gql, useMutation } from '@apollo/client';
import { passwordPattern } from '../../utils/patterns';
import {
  lPhantPrivacyPolicyLink,
  lPhantServiceTermsLink,
} from '../../utils/variables';
import IconEmail from '../../assets/images/icono-email.svg';
import LogoBlack from '../../assets/images/logo-black.svg';
import './SignUp.scss';

const SIGN_UP = gql`
  mutation SignUp($userToSignUp: UserToSignUp!) {
    signUp(userToSignUp: $userToSignUp) {
      id
    }
  }
`;

function SignUp(props) {
  const history = useHistory();
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  const amountParam = query.get('amount');
  const monthsParam = query.get('months');

  const [showInfoModal, setShowInfoModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const handleOkInfo = () => {
    setShowInfoModal(false);
    if (!amountParam || !monthsParam) {
      history.push('/dashboard');
    } else {
      history.push(
        `/solicitud-de-credito/?amount=${amountParam}&months=${monthsParam}`
      );
    }
  };

  const handleOkError = () => {
    setShowErrorModal(false);
    if (!amountParam || !monthsParam) {
      history.push('/dashboard');
    } else {
      history.push(
        `/solicitud-de-credito/?amount=${amountParam}&months=${monthsParam}`
      );
    }
  };

  const [signUpMutation, { error: requestSignUpError, loading }] = useMutation(
    SIGN_UP,
    {
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
      onCompleted: () => {
        if (requestSignUpError) {
          setShowErrorModal(true);
        } else {
          setShowInfoModal(true);
        }
      },
    }
  );

  const [userToSignUp, setForm] = useState({});

  const onChange = (value) => {
    setForm({
      ...userToSignUp,
      ...value,
    });
  };

  const onFinish = () => {
    signUpMutation({
      variables: {
        userToSignUp,
      },
    });
  };

  const onCheckboxChange = () => {
    setForm({
      ...userToSignUp,
      acceptedTerms: !userToSignUp.acceptedTerms,
    });
  };

  const { intl } = props;

  return (
    <div className='AuthForm Signup flex-row'>
      <Modal
        visible={showErrorModal}
        title=''
        footer={[
          <Button type='primary' onClick={handleOkError}>
            OK
          </Button>,
        ]}
      >
        <div className='email-modal'>
          <Image src={LogoBlack} />
          <div>Bienvenidos a L-Phant</div>,
          <Image src={IconEmail} />
          <div>
            No hemos podido realizar el proceso de inscripción y enviar el
            correo a:
          </div>
          <div>{userToSignUp.email}</div>
          <div>Reintente mas tarde nuevamente.</div>
        </div>
      </Modal>

      <Modal
        visible={showInfoModal}
        title=''
        footer={[
          <Button type='primary' onClick={handleOkInfo}>
            OK
          </Button>,
        ]}
      >
        <div className='email-modal'>
          <Image src={LogoBlack} />
          <div>Bienvenidos a L-Phant</div>,
          <Image src={IconEmail} />
          <div>Hemos enviado un correo a:</div>
          <div>{userToSignUp.email}</div>
          <div>
            Deberás verificar tu correo electrónico para poder continuar la
            solicitud de tu cripto crédito.
          </div>
        </div>
      </Modal>

      <div className='col background-image'></div>
      <div className='col background-image-text-1'>
        <h1>
          <FormattedMessage id='create-account-title'></FormattedMessage>
        </h1>
      </div>
      <div className='col background-image-text-2'>
        <a
          target='_blank'
          rel='noopener noreferrer external'
          href={lPhantServiceTermsLink}
          className='password-recovery-link'
        >
          <h4>
            <FormattedMessage id='credit-terms-tab'></FormattedMessage>
          </h4>
        </a>
        <a
          target='_blank'
          rel='noopener noreferrer external'
          href={lPhantPrivacyPolicyLink}
          className='password-recovery-link'
        >
          <h4>
            <FormattedMessage id='credit-policy-tab'></FormattedMessage>
          </h4>
        </a>
      </div>
      <div className='col col-vertical-center col-center'>
        <Form
          layout='vertical'
          name='signUp'
          onValuesChange={onChange}
          onFinish={onFinish}
        >
          <div className='logo'>
            <Logo />
          </div>
          <h1>
            <FormattedMessage id='sign-up-title'></FormattedMessage>
          </h1>
          <Form.Item
            label={intl.formatMessage({ id: 'email-label' })}
            name='email'
            rules={[
              {
                type: 'email',
                required: true,
                message: intl.formatMessage({ id: 'email-required-error' }),
              },
            ]}
          >
            <Input
              type='email'
              placeholder={intl.formatMessage({
                id: 'sign-up-email-placeholder',
              })}
              size='large'
            />
          </Form.Item>
          <Form.Item
            label={intl.formatMessage({ id: 'password-label' })}
            name='password'
            hasFeedback
            rules={[
              {
                required: true,
                pattern: passwordPattern,
                message: intl.formatMessage({ id: 'invalid-password-error' }),
              },
              {
                min: 8,
                required: true,
                message: intl.formatMessage({
                  id: 'password-required-error',
                }),
              },
            ]}
          >
            <Input.Password
              placeholder={intl.formatMessage({
                id: 'sign-up-password-placeholder',
              })}
              size='large'
            />
          </Form.Item>
          <Form.Item
            label={intl.formatMessage({ id: 'firstName-label' })}
            name='firstName'
            rules={[
              {
                required: true,
                message: intl.formatMessage({
                  id: 'firstName-required-error',
                }),
              },
            ]}
          >
            <Input
              placeholder={intl.formatMessage({
                id: 'firstName-placeholder',
              })}
              size='large'
            />
          </Form.Item>
          <Form.Item
            label={intl.formatMessage({ id: 'lastName-label' })}
            name='lastName'
            rules={[
              {
                required: true,
                message: intl.formatMessage({
                  id: 'lastName-required-error',
                }),
              },
            ]}
          >
            <Input
              placeholder={intl.formatMessage({ id: 'lastName-placeholder' })}
              size='large'
            />
          </Form.Item>
          <Form.Item name='acceptedTerms'>
            <Checkbox
              checked={userToSignUp.acceptedTerms}
              onChange={onCheckboxChange}
            >
              {intl.formatMessage({ id: 'sign-up-terms-required-1' })}

              <a
                target='_blank'
                rel='noopener noreferrer external'
                href={lPhantServiceTermsLink}
                className='password-recovery-link'
              >
                {intl.formatMessage({ id: 'sign-up-link-terms-required' })}
              </a>

              {intl.formatMessage({ id: 'and' })}

              <a
                target='_blank'
                rel='noopener noreferrer external'
                href={lPhantPrivacyPolicyLink}
                className='password-recovery-link'
              >
                {intl.formatMessage({
                  id: 'sign-up-link-privacy-policy-required',
                })}
              </a>
            </Checkbox>
          </Form.Item>
          <Form.Item>
            <div className='buttons-container'>
              <Button
                loading={loading}
                disabled={!userToSignUp.acceptedTerms}
                type='primary'
                htmlType='submit'
                style={{ marginRight: '1vw' }}
              >
                {intl.formatMessage({ id: 'create-account-button' })}
              </Button>
              <Link
                to={
                  amountParam && monthsParam
                    ? `/iniciar-sesion/?amount=${amountParam}&months=${monthsParam}`
                    : '/iniciar-sesion'
                }
              >
                <Button
                  disabled={loading}
                  type='secondary'
                  ghost
                  style={{ marginLeft: '1vw' }}
                >
                  {intl.formatMessage({ id: 'login-button' })}
                </Button>
              </Link>
            </div>
          </Form.Item>
          <p>
            <FormattedMessage id='login-label'></FormattedMessage>
            <Link
              to={
                amountParam && monthsParam
                  ? `/iniciar-sesion/?amount=${amountParam}&months=${monthsParam}`
                  : '/iniciar-sesion'
              }
            >
              <FormattedMessage id='login-link'></FormattedMessage>
            </Link>
          </p>
        </Form>
        <div className='terms-tablet'>
          <a
            target='_blank'
            rel='noopener noreferrer external'
            href={lPhantServiceTermsLink}
            className='password-recovery-link'
          >
            <h4>
              <FormattedMessage id='credit-terms-tab'></FormattedMessage>
            </h4>
          </a>
          <a
            target='_blank'
            rel='noopener noreferrer external'
            href={lPhantPrivacyPolicyLink}
            className='password-recovery-link'
          >
            <h4>
              <FormattedMessage id='credit-policy-tab'></FormattedMessage>
            </h4>
          </a>
        </div>
      </div>
    </div>
  );
}

export default injectIntl(SignUp);
