import React, { useState, useEffect } from "react";
import {
  UploadOutlined,
  CheckOutlined,
  InboxOutlined,
} from "@ant-design/icons";
import { Button, notification, Upload } from "antd";
import axios from "axios";
import { gql, useLazyQuery, useMutation } from "@apollo/client";

import "./FileUpload.scss";

const { Dragger } = Upload;

const GET_SIGNED_URL = gql`
  query GetSignedUrl($fileExtension: FileExtension!) {
    getSignedUrl(fileExtension: $fileExtension) {
      signedRequest
      url
    }
  }
`;

const FileUpload = ({
  label,
  name,
  placeholder,
  icon,
  value,
  getFileUrl,
  draggable,
}) => {
  const getMutation = () => {
    switch (name) {
      case "addressDocument":
        return gql`
          mutation UserAddAddressDocument($document: String!) {
            userAddAddressDocument(document: $document) {
              id
            }
          }
        `;
      case "idCard":
        return gql`
          mutation UserAddIdCard($document: String!) {
            userAddIdCard(document: $document) {
              id
            }
          }
        `;
      case "incomeDocument":
        return gql`
          mutation UserAddIncomeDocument($document: String!) {
            userAddIncomeDocument(document: $document) {
              id
            }
          }
        `;
      case "bankStatement":
        return gql`
          mutation UserAddBankStatement($document: String!) {
            userAddBankStatement(document: $document) {
              id
            }
          }
        `;
      default:
        return gql`
          mutation UserAddOtherDocument($document: String!) {
            userAddOtherDocument(document: $document) {
              id
            }
          }
        `;
    }
  };

  const [uploadFileUrl, { loading }] = useMutation(getMutation());

  const [getSignedUrl, { loading: loading_url, data: aws, error }] =
    useLazyQuery(GET_SIGNED_URL, {
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
      onError: () => {
        setUploadError(true);
      },
    });

  const [uploading, setUploading] = useState();

  const [uploadError, setUploadError] = useState(false);

  const [fileList, setFileList] = useState([]);

  const [url, setUrl] = useState();

  const [file, setFile] = useState();

  if (uploadError) {
    notification.error({
      message: "Error subiendo archivo",
      description: "El archivo no ha podido ser enviado correctamente",
      duration: 5,
      placement: "bottomRight",
    });
    setFileList([
      {
        ...fileList[0],
        status: "error",
      },
    ]);
    setUploadError(false);
  }

  useEffect(() => {
    if (aws) {
      const instance = axios.create();
      instance
        .put(aws.getSignedUrl.signedRequest, file, {
          headers: { "Content-Type": file.type },
        })
        .then((_) => {
          setUploading(false);
          setFileList([
            {
              ...fileList[0],
              status: "done",
              url: aws.getSignedUrl.url,
            },
          ]);

          if (getFileUrl) {
            getFileUrl(aws.getSignedUrl.url);
          } else {
            uploadFileUrl({
              variables: {
                document: aws.getSignedUrl.url,
              },
            })
              .then(() => setUrl(aws.getSignedUrl.url))
              .catch(() => {
                setUploadError(true);
              });
          }
        })
        .catch((_) => {
          setUploadError(true);
          setUploading(false);
        });
    }
    // eslint-disable-next-line
  }, [aws, file]);

  const uploadFile = ({ file: raw_file, onSuccess }) => {
    setFileList([
      {
        uid: "1",
        name: raw_file.name,
        status: "uploading",
      },
    ]);
    onSuccess("Ok");
    setUploading(true);
    setFile(raw_file);
    getSignedUrl({
      variables: {
        fileExtension: raw_file.type.split("/")[1],
      },
    });
  };

  useEffect(() => {
    if (error) {
      setFileList([
        {
          ...fileList[0],
          status: "error",
        },
      ]);
    }
    // eslint-disable-next-line
  }, [error]);

  const completed = value || url;

  const buttonIcon =
    icon || (completed ? <CheckOutlined /> : <UploadOutlined />);

  const dynamicPlaceholder = completed
    ? "Remplazar archivo"
    : placeholder || "Selecciona un archivo";

  const isLoading = uploading || loading || loading_url;

  if (draggable) {
    return (
      <div className="FileUpload-draggable">
        <Dragger
          name={name}
          isImageUrl={false}
          customRequest={uploadFile}
          fileList={fileList}
          multiple={false}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">{dynamicPlaceholder}</p>
        </Dragger>
      </div>
    );
  }

  return (
    <div className="FileUpload">
      <label>{label}</label>
      <Upload
        name={name}
        isImageUrl={false}
        customRequest={uploadFile}
        fileList={fileList}
        multiple={false}
      >
        <Button loading={isLoading} className="light" icon={buttonIcon}>
          {dynamicPlaceholder}
        </Button>
      </Upload>
    </div>
  );
};

export default FileUpload;
