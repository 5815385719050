import React from "react";
import { Spin as AntdSpin } from "antd";

import "./Spin.scss";

const Spin = () => (
  <div className="Spin">
    <AntdSpin></AntdSpin>
  </div>
);

export default Spin;
