import * as React from "react";
import { IntlProvider } from "react-intl";
import messages from "./messages";

export default function Intl(props) {
  return (
    <IntlProvider messages={messages} locale="fr" defaultLocale="en">
      {props.children}
    </IntlProvider>
  );
}
